import React from 'react'
import "./CAHeading.css";
import { TiThList } from "react-icons/ti";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { Link } from 'react-router-dom';

function CAHeading() {
    return (
        <div className='MainHeadingCA'>
            <div className='heading' >
                <h3>Properties-CA</h3>
            </div>
            <div className='ListGridIcons' >
                <Link to="/Properties-CA/ListView" title='ListView' style={{ textDecoration: "none", color: "black" }} >
                    <TiThList />
                </Link>
                <Link to="/Properties-CA/GridView" title="GridView" style={{ textDecoration: "none", color: "black" }}>
                    <BsFillGrid3X3GapFill />
                </Link>

            </div>
        </div>
    )
}

export default CAHeading;
