import React from 'react'
import Header from '../../Components/Header/Header';
import BackgroundImage from "../../Components/Hero/BackgroundImage/BackgroundImage";
import Navbar from '../../Components/Hero/NavBar/Navbar';
import "./LandingPage.css";


//import ImageSlider from '../../Components/Carousel/ImageSlider';
//import "./landingpage.css";
function LandingPage() {
    return (
        <div className='MainLandingPageDiv' >
            <Header/>
            <BackgroundImage/>
            <Navbar/>
        </div>
    )
}

export default LandingPage;
