import React from 'react';
import USProperties from '../USProperties/USProperties';
import "./MainGridUS.css";

function MainGridUS() {
    const numberOfSliders = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    return (
        <div className='maindivUS'>
            <div className='contentUS'>
                <div className='sliderUS'>
                    {numberOfSliders.map((index) => (
                        <USProperties key={index} />
                    ))}
                </div>
            </div>
            <div className='ButtonConatinerUS'>
                <button>Load More</button>
            </div>
        </div>
    );
}

export default MainGridUS;