import React, {useState, useEffect}from 'react'
import "./FindAgentDetails.css";
import Image3 from "../../../Assets/Image3.jpg";
import Image5 from "../../../Assets/Image5.jpg";
import Image9 from "../../../Assets/Image9.jpg";

function FindAgentDetails() {
  
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition >=800) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className= {`FindAgentDetailsMainDiv ${isFixed ? 'fixed-bottom' : ''}`} >
        <div className='AgentProfileContact'>
          <p style={{ margin: 0 }}>Contact</p>
          <div className='AgentNameListContactCard'>
            <div className='AgentOfficeNumber' >
              <p style={{ fontWeight: "bold" }}>Office</p>
              <p>352-455-0044</p>
            </div>
            <div className='AgentMobileNum'>
              <p style={{ fontWeight: "bold" }}>Mobile</p>
              <p>352-455-0044</p>
            </div>
            <div className='AgentEmail'>
              <p style={{ fontWeight: "bold" }}>Email</p>
              <p>autumn@makinsales.com</p>
            </div>
          </div>
        </div>
        <div className='AgentProfileFindAgent'>
          <p style={{ margin: 0 }}>Find Agent</p>
          <div className='FindAgentListInputFields'>
            <input type="text"  placeholder='Search Agent Name'/>
            <input type="text" placeholder='All Catagories' />
            <input type="text" placeholder='All cities' />
          </div>
          <div className='SearchAgentDetailsBtnContainer'>
            <button>Search Agent</button>
          </div>
        </div>
        <div className='AgentProfileContact'>
          <p style={{ margin: 0 }}>Recent Viewed</p>
          <div className='AgentNameListInnerDiv'>
            <div className='AgentProfileImageLocation'>
              <div className='AgentListNameLocationImageContainer'>
                <img src = {Image3} alt="Apartment" />
              </div>
              <div className='AgentRecentViewedLocationPriceTextDiv1'>
                <p style = {{margin: 0}}>157 BOUGAINVILLEA DR, LEESBURG, FL 34748</p>
                <p style = {{margin: 0, fontWeight: "bold"}}>$159,900</p>
              </div>
            </div>
            <div className='AgentProfileImageLocation'>
              <div className='AgentListNameLocationImageContainer'>
                <img src = {Image9} alt="Apartment" />
              </div>
              <div className='AgentRecentViewedLocationPriceTextDiv1'>
                <p style = {{margin: 0}}>157 BOUGAINVILLEA DR, LEESBURG, FL 34748</p>
                <p style = {{margin: 0, fontWeight: "bold"}}>$159,900</p>
              </div>
            </div>
            <div className='AgentProfileImageLocation'>
              <div className='AgentListNameLocationImageContainer'>
                <img src = {Image5} alt="Apartment" />
              </div>
              <div className='AgentRecentViewedLocationPriceTextDiv1'>
                <p style = {{margin: 0}}>157 BOUGAINVILLEA DR, LEESBURG, FL 34748</p>
                <p style = {{margin: 0, fontWeight: "bold"}}>$159,900</p>
              </div>
            </div>
          </div>
        </div>
    </section>
  )
}

export default FindAgentDetails;
