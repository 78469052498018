import React from 'react'
import "./RecentDetailsSection.css";
import { SlCalender } from "react-icons/sl";

function RecentDetailsSection() {
    return (
        <section className='MainDivDetailSection'>
            <div className='RecentDetailsSectionInnerDiv'>
                <div className='DetailsSectionDiv'>
                    <p>Details</p>
                    <div className='CalenderIconDetailSection'>
                        <SlCalender />
                        <p>Updated on March 29, 2024 at 1:11 pm</p>
                    </div>
                </div>
                <div className='CompleteDetailsSection'>
                    <div className='DetailsCol1'>
                        <div className='PropertyDetailsDiv'>
                            <h4>Property ID:</h4>
                            <p>220175849</p>
                        </div>
                        <div className='PriceDetailsDiv'>
                            <h4>Price:</h4>
                            <p>$1,195,000</p>
                        </div>
                        <div className='PropertySizeDetailsDiv'>
                            <h4>Property Size:</h4>
                            <p>2449 sqft
                            </p>
                        </div>
                        <div className='LandAreaDetailsDiv'>
                            <h4>Land Area:</h4>
                            <p>0.43 Acres</p>
                        </div>
                        <div className='BedroomsDetailsDiv'>
                            <h4>Bedrooms:</h4>
                            <p>3</p>
                        </div>
                        <div className='BathroomsDetailsDiv'>
                            <h4>Bathrooms:</h4>
                            <p>4</p>
                        </div>
                    </div>
                    <div className='DetailsCol2'>
                        <div className='GaragesDetailsDiv'>
                            <h4>Garages:</h4>
                            <p>2</p>
                        </div>
                        <div className='YearBuiltDetailsDiv'>
                            <h4>Year Built:</h4>
                            <p>2023</p>
                        </div>
                        <div className='PropertyTypeDetailsDiv'>
                            <h4>Property Type:</h4>
                            <p>Residential</p>
                        </div>
                        <div className='PropertyStatusDetailsDiv'>
                            <h4>Property Status:</h4>
                            <p>For Sale
                            </p>
                        </div>
                        <div className='MLSDetailsDiv'>
                            <h4>MLS#:</h4>
                            <p>220175849
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RecentDetailsSection;
