import React from 'react'
import CANavbar from '../../Components/CANavbar/CANavbar';
import CAHeading from '../../Components/CAHeading/CAHeading';
import CAListingNavbar from '../../Components/CAListingNavbar/CAListingNavbar';
import MainGridCA from '../../Components/Carousel/MainGridCA/MainGridCA';

function PropertiesCA() {
  return (
    <div>
      <CANavbar/>
      <CAHeading/>
      <CAListingNavbar/>
      <MainGridCA/>
    </div>
  )
}

export default PropertiesCA;
