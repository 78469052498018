import React from 'react'
import "./CAListingNavbar.css";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

function CAListingNavbar() {
    return (
        
            <div className='CAcenterNav'>
                <div className='CAPropertyTypeNav'>
                    <div className='CAall'>
                        <p>All</p>
                    </div>
                    <div className='CArent'>
                        <p> For Rent</p>
                    </div>
                    <div className='CAsale'>
                        <p>For Sale</p>
                    </div>
                </div>
                <div className='CASorting'>
                        <p>Sort By: </p>
                        <p style={{color: "gray"}}>Default Order</p>
                        <MdOutlineKeyboardArrowDown />
                        <ul>
                            <li style = {{borderBottom: "1px solid #DEE5ED"}}>Default Order</li>
                            <li style = {{borderBottom: "1px solid #DEE5ED"}}>Price - Low to High</li>
                            <li style = {{borderBottom: "1px solid #DEE5ED"}}>Price - High to Low</li>
                            <li style = {{borderBottom: "1px solid #DEE5ED"}}>Featured Listings First</li>
                            <li style = {{borderBottom: "1px solid #DEE5ED"}}>Date - Old to New</li>
                            <li style = {{borderBottom: "1px solid #DEE5ED"}}>Date - New to Old</li>
                        </ul>
                </div>
            </div>
        
    )
}

export default CAListingNavbar;
