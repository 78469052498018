import React, { useState } from 'react'
import "./Properties.css";

import Image1 from "../../Assets/Image1.jpg";
import Image2 from "../../Assets/Image2.jpg";
import Image3 from "../../Assets/Image3.jpg";
import Image4 from "../../Assets/Image4.jpg";
import Image5 from "../../Assets/Image5.jpg";
import Image6 from "../../Assets/Image6.jpg";
import Image7 from "../../Assets/Image7.jpg";
import image8 from "../../Assets/image8.jpg";
import image9 from "../../Assets/Image9.jpg";
import USIMG from "../../Assets/USIMG.jpg";
import BGIMG from "../../Assets/BGIMG.jpg";
import BGIMGFlorida from "../../Assets/BGIMGFlorida.jpg";

import { TbListDetails } from "react-icons/tb";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdOutlineLocationOn } from "react-icons/md";
import { Link } from 'react-router-dom';

function Properties() {
  const images = [
    Image1,
    image9,
    Image3,
    Image4,
    BGIMGFlorida,
    Image6,
    Image7,
    image8,
    Image2,
    USIMG,
    BGIMG,
    Image5,
  ];

  const [selectedImage, setSelectedImage] = useState(images[0]);



  const handleImageClick = (image) => {
    setSelectedImage(image);
  };
  return (
    <section className='PropertiesMainSectionDiv'>
      <div className='PropertiesMainInnerDiv' >
        <div className='PropertiesCreateListingsBtnText'>
          <p>Properties</p>
          <Link to="/CreateListings">   <button > Create Listings </button>  </Link>
        </div>
      </div>

      <div className='PropertiesInfoHeader'>
        <div>
          <p style={{ fontSize: "14px", margin: 0, marginLeft: "4px" }}>CUSTOM POOL HOME W/ SPECTACULAR LAKE VIEW!</p>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <MdOutlineLocationOn style={{ fontSize: "20px" }} />
            <p style={{ margin: "2px", fontSize: "13px" }}>  12519, Nicolette Court, Clermont, Lake County, Florida, 34711, United States </p>
          </div>

        </div>


        <div className='PropertiesInfoButtons'>
          <div>
            <p style={{ fontSize: "13px" }}>Published on 20/20/2020</p>
          </div>
          <div className='ApprovedBtnDiv'>
            <button>Approved</button>
          </div>
          <div style={{
            border: '1px solid gray', borderRadius: "3px",
            height: "1.5rem", width: "2rem", marginTop: '4px'
          }}>
            <TbListDetails style={{ marginLeft: "5px", marginTop: "2px", fontSize: "20px" }} />
          </div>
          <div className='ActionsBtnDiv'>
            <button>Actions</button>
          </div>
        </div>

      </div>

      <div className='ViewListings'>

        <div className='MainImagesSection'>
          <div className='DashboardPropertiesImageContainer'>
            <img src={selectedImage} alt="Apartment" />
          </div>
          <div className='ImageSliderCol'>
            {images.map((image, index) => (
              <div className={`ImageSliderRow1 ${selectedImage === image ? 'selected' : ''}`} key={index}>
                <img src={image} alt="" onClick={() => handleImageClick(image)} />
              </div>
            ))}
          </div>
        </div>


        <div className='PropertiesMapDiv' >
          <iframe
            className='MapIframe'
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2924.2675937832673!2d-109.86666782337795!3d42.867195802910935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x875796a8faa826a9%3A0xccb88d30b89a612!2s34%20N%20Franklin%20Ave%20687%202430%2C%20Pinedale%2C%20WY%2082941%2C%20USA!5e0!3m2!1sen!2s!4v1714434981347!5m2!1sen!2s"
            // width="765"
            // height="350"
            style={{ border: "1px solid #DEE5ED", borderRadius: "15px", }}
            allowFullScreen=""
            loading="lazy"
            title="Google Map"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>

      </div>

      <div style={{ padding: '20px' }}>
        <div className='ListingsInfoInnerDiv'>
          <p >Listing Information</p>
          <p style={{ fontSize: '12px' }}>Updated on 26 March, 2024</p>
        </div>

        <div className='MainSectionListingInformation'>
          <div className='FeaturesRow1'>
            <div className='PropertiesInfoCard' >
              <div className='ApartmentStatus' >
                <p style={{ fontWeight: "bold" }}>Status:</p>
                <p style={{ marginLeft: "63px", }}>Active </p>
              </div>
              <div className='ApartmentPrice'>
                <p style={{ fontWeight: "bold" }}>Price:</p>
                <p style={{ marginLeft: "73px", }}>2,680 </p>
              </div>
              <div className='ApartmentMLS'>
                <p style={{ fontWeight: "bold" }}>MLS#:</p>
                <p style={{ marginLeft: "67px", }}>0357464</p>
              </div>
              <div className='ApartmentPropertyType'>
                <p style={{ fontWeight: "bold" }}>Property Type:</p>
                <p style={{ marginLeft: "11px", }}>Single Family Home</p>
              </div>
              <div className='ApartmentPropertySize'>
                <p style={{ fontWeight: "bold" }}>Property Size:</p>
                <p style={{ marginLeft: "17px", }}>2,680 sqft</p>
              </div>
            </div>

            <div className='PropertiesFeaturesCard' >
              <div className='ApartmentLandArea' >
                <p style={{ fontWeight: "bold" }}>Land Area:</p>
                <p style={{ marginLeft: "63px", }}>0.35 acred </p>
              </div>
              <div className='ApartmentYearBuild'>
                <p style={{ fontWeight: "bold" }}>Year Build:</p>
                <p style={{ marginLeft: "62px", }} >1998 </p>
              </div>
              <div className='ApartmentCounty'>
                <p style={{ fontWeight: "bold" }}>County:</p>
                <p style={{ marginLeft: "81px", }}>Lake</p>
              </div>
              <div className='ApartmentState'>
                <p style={{ fontWeight: "bold" }}>State/Province:</p>
                <p style={{ marginLeft: "30px", }}>Florida</p>
              </div>
              <div className='ApartmentPropertyID'>
                <p style={{ fontWeight: "bold" }}>Property ID:</p>
                <p style={{ marginLeft: "56px", }}>123456</p>
              </div>
            </div>
          </div>

          <div className='FeaturesRow2'>
            <div className='PropertiesFeatures3Card' >
              <div>
                <ul style={{ margin: 0, paddingLeft: 0, fontSize: "13px" }}>
                  <li>Air Conditioning</li>
                  <li>Built-In Oven</li>
                  <li>Ceiling Fans(s)</li>
                  <li>Dishwasher</li>
                  <li>Disposal</li>
                </ul>
              </div>

              <div>
                <ul style={{ margin: 0, paddingLeft: 0, fontSize: "13px" }}>
                  <li>Laundry</li>
                  <li>Lawn</li>
                  <li>Lighting</li>
                  <li>Microwave</li>
                  <li>Open Floorplan</li>
                </ul>
              </div>

            </div>
            <div className='PropertiesFeatures2Card' >
              <div>
                <ul style={{ margin: 0, paddingLeft: 0, fontSize: "13px" }}>
                  <li>Dryer</li>
                  <li>Electric Water Heater</li>
                  <li>High Ceilings</li>
                  <li>Irrigation System</li>
                  <li>Outdoor Shower</li>
                </ul>
              </div>

              <div>
                <ul style={{ margin: 0, paddingLeft: 0, fontSize: "13px" }}>
                  <li>Refrigerator</li>
                  <li>Sliding Doors</li>
                  <li>Split Bedroom</li>
                  <li>Swimming Pool</li>
                  <li>TV Cable</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='ArrowsButtonContainer'>
        <div className='MdOutlineKeyboardArrowLeftDiv'>
          <MdOutlineKeyboardArrowLeft className='MdOutlineKeyboardArrowLeft' />
        </div>
        <div className='MdOutlineKeyboardArrowRightDiv'>
          <MdOutlineKeyboardArrowRight className='MdOutlineKeyboardArrowRight' />
        </div>

      </div>
    </section>
  )
}

export default Properties;
