import React from 'react'
import Layout from '../Layout/Layout';
import USHeading from '../Components/USHeading/USHeading';
import ListingNavbar from '../Components/ListingNavbar/ListingNavbar';
import UsNavbar from '../Components/USNavbar/UsNavbar';
function USLayout() {
  return (
    <div>
      <Layout />
      <UsNavbar />
      <USHeading />
      <ListingNavbar />
    </div>
  )
}

export default USLayout;
