import React, {useState} from 'react'
import "./CreateListings.css";
import CreateListingsTextEditor from '../TextEditor/CreateListingTextEditor/CreateListingTextEditor';
import { GrFormNextLink } from "react-icons/gr";
import { Link } from 'react-router-dom';

function CreateListings() {

  const [isClicked, setIsClicked] = useState(false);

  const handleInputClick = () => {
    setIsClicked(true);
};


  return (
    <section className='CreateListingsSectionMainDiv'>
      <div className='CreateListingsSectionInnerDiv' >
        <div className='CreateListingsHeaderTextBtn'>
          <p>Create Listings</p>
          <button >Save as Draft</button>
        </div>
      </div>

      <div className='CreatListingsFormSection'>
        <div className='PropertyTitleTextInputField'>
          <p style={{ fontWeight: "bold", fontSize: "14px" }}>Property Title</p>
          <input type="text" placeholder='Enter your Property title' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
        </div>

        <p style={{ fontWeight: "bold", fontSize: "14px" }}>Content</p>
        <CreateListingsTextEditor />

        <div className='CreateListingsInputFieldsRow'>
          <div>
            <p>Type</p>
            <input type="text" placeholder='Type' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
          </div>
          <div>
            <p>Status</p>
            <input type="text" placeholder='Status' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
          </div>
          <div>
            <p>Label</p>
            <input type="text" placeholder='Label' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
          </div>
        </div>
      </div>

      <div className='CreatListingsFormSection2'>

        <div className='CreateListingsInputFieldsRow1'>
          <div className='SaleRentPriceInputField'>
            <p>Sale or Rent Price</p>
            <input type="text" placeholder='Enter the Price' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
          </div>
          <div className='SecondPriceInputField'>
            <p>Second Price <span style={{ fontWeight: "normal" }}>(optional)</span></p>
            <input type="text" placeholder='Enter the Second Price' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
          </div>
        </div>

        <div className='CreateListingsInputFieldsRow2'>
          <div className='AfterThePriceInputField'>
            <p>After the Price</p>
            <input type="text" placeholder='Enter the Price' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
            <p style={{ fontSize: "10px", fontWeight: "normal", margin: "5px" }}>For example: Monthly</p>
          </div>
          <div className='PricePrefixInputField'>
            <p>Price Prefix</p>
            <input type="text" placeholder='Enter the Second Price' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
            <p style={{ fontSize: "10px", fontWeight: "normal", margin: "5px" }}>For example: Start from</p>
          </div>
        </div>

      </div>

      <div className='CreateListingsFooter'>
        <div className='CreateListingBtnContainerFooter'>

          <div className='CreateListingCancelBtnIconContainer'>
            <button>Cancel</button>
          </div>

          <div className='CreateListingNextBtnIconContainer'>
            <Link className='NextBtnLink' to= "/CreateListings-Page2">  <button>Next <GrFormNextLink style={{ fontSize: '30px', marginLeft: "10px" }} />
            </button>
            </Link>
          </div>

        </div>
      </div>


    </section>
  )
}

export default CreateListings;
