import React from 'react'
import "./Insights.css";
import BarChartInsights from "../../Dashboard/Insights/BarChartInsights/BarChartInsights";
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaLongArrowAltDown } from "react-icons/fa";
import DevicesPieChart from './DevicesPieChart/DevicesPieChart';
import TopPlatformPieChart from './TopPlatformPieChart/TopPlatformPieChart';
import TopBrowserPieChart from './TopBrowserPieChart/TopBrowserPieChart';
import { CiGlobe } from "react-icons/ci";

function Insights() {
  return (
    <div className='MainInsightsDiv'>
      <div className='MainInsightHeaderSection'>
        <p>Overview</p>
      </div>

      <div className='BarchartInsightsValues'>
        <div className='InsightsBarChartDiv'>
          <div className='InsightsTextFilterDIv'>
            <div>
              <p style={{ marginLeft: "10px", fontSize: '15px' }}>Insights</p>
            </div>
            <div className='SelectListingsFilterInputField'>
              <input type="text" placeholder='Select listings' />
            </div>
          </div>
          <BarChartInsights />
        </div>
        <div className='ViewsInsightsMainDiv'>
          <p style={{ marginLeft: "20px", fontSize: '15px' }}>Views</p>
          <div className='ViewsCardLast24hrs' >
            <div className='NumOfDaysDiv'>
              <p style={{ fontSize: '12px', margin: 0 }}>Last</p>
              <p style={{ fontSize: "35px", fontWeight: "bold", margin: 0, }}>24</p>
              <p style={{ fontSize: "12px", margin: 0 }}>hrs</p>
            </div>
            <div className='PercentageValues' >
              <p style={{ fontSize: "35px", margin: 0, }}>30</p>
              <p style={{ fontSize: "12px", marginLeft: '5px', color: "green", margin: 0 }}>30% <FaLongArrowAltUp /> </p>
            </div>
          </div>
          <div className='ViewsCardLast24hrs' >
            <div className='NumOfDaysDiv'>
              <p style={{ fontSize: '12px', margin: 0, }}>Last</p>
              <p style={{ fontSize: "35px", fontWeight: "bold", margin: 0 }}>7</p>
              <p style={{ fontSize: "12px", margin: 0 }}>days</p>
            </div>
            <div className='PercentageValues' >
              <p style={{ fontSize: "35px", margin: 0, }}>6300</p>
              <p style={{ fontSize: "12px", marginLeft: '5px', color: "red", margin: 0 }}>145% <FaLongArrowAltDown /> </p>
            </div>
          </div>
          <div className='ViewsCardLast24hrs' >
            <div className='NumOfDaysDiv'>
              <p style={{ fontSize: '12px', margin: 0, }}>Last</p>
              <p style={{ fontSize: "35px", fontWeight: "bold", margin: 0 }}>15</p>
              <p style={{ fontSize: "12px", margin: 0 }}>days</p>
            </div>
            <div className='PercentageValues' >
              <p style={{ fontSize: "35px", margin: 0, }}>35</p>
              <p style={{ fontSize: "12px", marginLeft: '5px', color: "green", margin: 0 }}>32% <FaLongArrowAltUp /> </p>
            </div>
          </div>
          <div className='ViewsCardLast24hrs' >
            <div className='NumOfDaysDiv'>
              <p style={{ fontSize: '12px', margin: 0, }}>Last</p>
              <p style={{ fontSize: "35px", fontWeight: "bold", margin: 0 }}>30</p>
              <p style={{ fontSize: "12px", margin: 0 }}>days</p>
            </div>
            <div className='PercentageValues' >
              <p style={{ fontSize: "35px", margin: 0, }}>48</p>
              <p style={{ fontSize: "12px", marginLeft: '5px', color: "green", margin: 0 }}>21% <FaLongArrowAltUp /> </p>
            </div>
          </div>
        </div>

      </div>

      <div className='InsightsLastSection'>

        <div className='InsightsLastSectionCards'>
          <div className='InsightsCradsFirstRow' style={{ marginBottom: "30px" }}>
            <div className='DevicesDiv' >
              <p style={{ margin: 0, fontSize: '15px' }} >Devices</p>
              <DevicesPieChart />
            </div>
            <div className='TopPlatformsDiv' >
              <p style={{ margin: 0, fontSize: '15px' }}>Top Platforms</p>
              <TopPlatformPieChart />
            </div>
          </div>
          <div className='InsightsCardsSecondRow'>
            <div className='TopBrowsersDiv' >
              <p style={{ margin: 0, fontSize: '15px' }}>Top Browsers</p>
              <TopBrowserPieChart />
            </div>
            <div className='RefferalsDiv' >
              <p style={{ margin: 0, fontSize: '15px' }}>Refferals</p>
              <div className='Refferalrow1' style={{ marginTop: "10px" }}>
                <CiGlobe style={{ fontSize: "30px" }} />
                <p>realizty.comHello12345678444444444910</p>
                <p className='refferalViews' >132 views</p>
              </div>
              <div className='Refferalrow1'>
                <CiGlobe style={{ fontSize: "30px" }} />
                <p>facebook.comHello12345674444444449</p>
                <p className='refferalViews' >156 views</p>
              </div>
              <div className='Refferalrow1'>
                <CiGlobe style={{ fontSize: "30px" }} />
                <p>google.comHello12345674444444449</p>
                <p className='refferalViews' >14540 views</p>
              </div>
              <div className='Refferalrow1'>
                <CiGlobe style={{ fontSize: "30px" }} />
                <p>bing.comHello12345674444444449</p>
                <p className='refferalViews' >147804 views</p>
              </div>
            </div>
          </div>
        </div>

        <div className='UniqueViesSectionLast'>
          <div className='ViewsInsightsMainDiv'>
            <p style={{ marginLeft: "20px", fontSize: '15px' }}>Unique Views</p>
            <div className='ViewsCardLast24hrs' >
              <div className='NumOfHoursDiv'>
                <p style={{ fontSize: '12px', margin: 0, }}>Last</p>
                <p style={{ fontSize: "35px", fontWeight: "bold", margin: 0 }}>24</p>
                <p style={{ fontSize: "12px", margin: 0 }}>hrs</p>
              </div>
              <div className='PercentageValues' >
                <p style={{ fontSize: "35px", margin: 0, }}>30</p>
                <p style={{ fontSize: "12px", marginLeft: '5px', color: "green", margin: 0 }}>50% <FaLongArrowAltUp /> </p>
              </div>
            </div>
            <div className='ViewsCardLast7days' >
              <div className='NumOfDaysDiv'>
                <p style={{ fontSize: '12px', margin: 0, }}>Last</p>
                <p style={{ fontSize: "35px", fontWeight: "bold", margin: 0 }}>7</p>
                <p style={{ fontSize: "12px", margin: 0 }}>days</p>
              </div>
              <div className='PercentageValues' >
                <p style={{ fontSize: "35px", margin: 0, }}>63</p>
                <p style={{ fontSize: "12px", marginLeft: '5px', color: "red", margin: 0 }}>50% <FaLongArrowAltDown /> </p>
              </div>
            </div>
            <div className='ViewsCardLast15days' >
              <div className='NumOfDaysDiv'>
                <p style={{ fontSize: '12px', margin: 0, }}>Last</p>
                <p style={{ fontSize: "35px", fontWeight: "bold", margin: 0 }}>15</p>
                <p style={{ fontSize: "12px", margin: 0 }}>days</p>
              </div>
              <div className='PercentageValues' >
                <p style={{ fontSize: "35px", margin: 0, }}>35</p>
                <p style={{ fontSize: "12px", marginLeft: '5px', color: "green", margin: 0 }}>50% <FaLongArrowAltUp /> </p>
              </div>
            </div>
            <div className='ViewsCardLast30days' >
              <div className='NumOfDaysDiv'>
                <p style={{ fontSize: '12px', margin: 0, }}>Last</p>
                <p style={{ fontSize: "35px", fontWeight: "bold", margin: 0 }}>30</p>
                <p style={{ fontSize: "12px", margin: 0 }}>days</p>

              </div>
              <div className='PercentageValues' >
                <p style={{ fontSize: "35px", margin: 0, }}>48</p>
                <p style={{ fontSize: "12px", marginLeft: '5px', color: "green", margin: 0 }}>50% <FaLongArrowAltUp /> </p>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div >
  )
}

export default Insights;
