import React, {useState} from 'react'
import "./SocialMedia.css";

function SocialMedia() {

    const [isClicked, setIsClicked] = useState(false);

  const handleInputClick = () => {
    setIsClicked(true);
};


    return (
        <section className='MyProfileSocialMediaMainDiv'>
            <div className='MyProfileSocialMediaInnerDiv'>
                <div className='SocialMediaLeftSide'>
                    <p>Social Media</p>
                </div>
                <div className='SocialMediaRightSide'>
                    <div className='SocialMediaSectionRow1'>
                        <div>
                            <p>Facebook</p>
                            <input type="url" placeholder='Enter the Facebook URL' pattern="https?://.+" onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
                        </div>
                        <div>
                            <p>X</p>
                            <input type="url" placeholder='Enter the X URL' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
                        </div>
                    </div>

                    <div className='SocialMediaSectionRow2'>
                        <div>
                            <p>LinkedIn</p>
                            <input type="url" placeholder='Enter the LinkedIn URL' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
                        </div>
                        <div>
                            <p>Instagram</p>
                            <input type="url" placeholder='Enter the Instagram URL' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
                        </div>
                    </div>

                    <div className='SocialMediaSectionRow3'>
                        <div>
                            <p>Google Plus</p>
                            <input type="url" placeholder='Enter the GooglePlus' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
                        </div>
                        <div>
                            <p>Youtube</p>
                            <input type="url" placeholder='Enter the Youtube URL' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
                        </div>
                    </div>

                    <div className='SocialMediaSectionRow4'>
                        <div>
                            <p>TikTok</p>
                            <input type="url" placeholder='Enter the TikTok URL' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
                        </div>
                        <div>
                            <p>Telegram</p>
                            <input type="url" placeholder='Enter the Telegram URL' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
                        </div>
                    </div>

                    <div className='SocialMediaSectionRow5'>
                        <div>
                            <p>Pinterest</p>
                            <input type="url" placeholder='Enter the Pinterest URL' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
                        </div>
                        <div>
                            <p>Vimeo</p>
                            <input type="url" placeholder='Enter the Vimeo URL' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'}/>
                        </div>
                    </div>

                    <div className='SocialMediaSectionRow6'>
                        <div>
                            <p>Skype ID</p>
                            <input type="text" placeholder='Enter your Skype ID' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
                        </div>
                        <div>
                            <p>Website</p>
                            <input type="url" placeholder='Enter your Website url' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'}/>
                        </div>
                    </div>

                    <div className='SocialMediaUpdateProfileBtnContainer'>
                        <button>Update Profile</button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SocialMedia;
