// import React, { useState } from 'react';
// import "./SideBar.css";
// import RealiztyLogoLight from "../../Assets/RealiztyLogoLight.png";
// import { AiOutlineLineChart, AiOutlineHome, AiOutlineProfile, AiOutlineLogout, AiOutlineUnorderedList, AiOutlineUser } from 'react-icons/ai';
// import { MdOutlineDashboard } from "react-icons/md";
// import { MdOutlineLeaderboard } from "react-icons/md";
// import { MdKeyboardArrowRight } from "react-icons/md";
// import { Link } from 'react-router-dom';

// function SideBar() {
//     const [showPropertiesOptions, setShowPropertiesOptions] = useState(false);
//     const [showActivities, setShowActivities] = useState(false);
//     const [activeItem, setActiveItem] = useState('');
//     const [sidebarHeight, setSidebarHeight] = useState('100vh');

//     const handlePropertiesClick = () => {
//         setShowPropertiesOptions(!showPropertiesOptions);
//         setSidebarHeight(showPropertiesOptions ? '100vh' : 'auto');
//     };

//     const handleActivitiesClick = () => {
//         setShowActivities(!showActivities);
//     }

//     const handleItemClick = (itemName) => {
//         setActiveItem(itemName);
//     }

//     return (
//         <section className= 'SideBarMainDiv' style={{ height: sidebarHeight }}>
//             <div className='SideBarInnerDiv' style={{margin: '20px'}}>
//                 <div className='SidebarRealiztyLogoContainer'>
//                     <Link to="/"> <img src={RealiztyLogoLight} alt="Realizty Logo" /> </Link>
//                 </div>
//                 <div className='SidebarListItems'>
//                     <ul>
//                         <li className={activeItem === 'Dashboard' ? 'active' : ''} onClick={() => handleItemClick('Dashboard')}>
//                             <MdOutlineDashboard /> <Link to="/Dashboard"> Dashboard </Link> <MdKeyboardArrowRight onClick={handleActivitiesClick} />
//                         </li>
//                         {showActivities && (
//                             <>
//                                 <Link to="/notifications">
//                                     <li className={activeItem === 'Updates' ? 'active' : ''} onClick={() => handleItemClick('Updates')} style={{ padding: "10px", marginLeft: "3rem" }}>Updates</li>
//                                 </Link>
//                             </>
//                         )}
//                         <Link to="/Leads">
//                             <li className={activeItem === 'Leads' ? 'active' : ''} onClick={() => handleItemClick('Leads')}>
//                                 <MdOutlineLeaderboard /> Leads
//                             </li>
//                         </Link>
//                         <Link to="/Insights">
//                             <li className={activeItem === 'Overview' ? 'active' : ''} onClick={() => handleItemClick('Overview')}>
//                                 <AiOutlineLineChart /> Overview
//                             </li>
//                         </Link>
//                         <li className={activeItem === 'Properties' ? 'active' : ''} onClick={() => handleItemClick('Properties')}>
//                             <AiOutlineHome /> <Link to="/Properties"> Properties </Link>  <MdKeyboardArrowRight onClick={handlePropertiesClick} />
//                         </li>
//                         {showPropertiesOptions && (
//                             <>
//                                 <li className={activeItem === "All" ? 'active' : ''} onClick={() => handleItemClick("All")} style={{ padding: "10px", marginLeft: "3rem" }}>All</li>
//                                 <li className={activeItem === "Published" ? 'active' : ''} onClick={() => handleItemClick("Published")} style={{ padding: "10px", marginLeft: "3rem" }}>Published</li>
//                                 <li className={activeItem === "Pending" ? 'active' : ''} onClick={() => handleItemClick("Pending")} style={{ padding: "10px", marginLeft: "3rem" }}>Pending</li>
//                                 <li className={activeItem === "Expired" ? 'active' : ''} onClick={() => handleItemClick("Expired")} style={{ padding: "10px", marginLeft: "3rem" }}>Expired</li>
//                                 <li className={activeItem === "Draft" ? 'active' : ''} onClick={() => handleItemClick("Draft")} style={{ padding: "10px", marginLeft: "3rem" }}>Draft</li>
//                                 <li className={activeItem === "OnHold" ? 'active' : ''} onClick={() => handleItemClick("PublisOnHoldhed")} style={{ padding: "10px", marginLeft: "3rem" }}>OnHold</li>
//                                 <li className={activeItem === "Disapproved" ? 'active' : ''} onClick={() => handleItemClick("Disapproved")} style={{ padding: "10px", marginLeft: "3rem" }}>Disapproved</li>
//                             </>
//                         )}
//                         <Link to="/CreateListings">
//                             <li className={activeItem === 'CreateListing' ? 'active' : ''} onClick={() => handleItemClick('CreateListing')}>
//                                 <AiOutlineUnorderedList /> Create a Listing
//                             </li>
//                         </Link>
//                         <Link to="/MemberShip">
//                             <li className={activeItem === 'Membership' ? 'active' : ''} onClick={() => handleItemClick('Membership')}>
//                                 <AiOutlineUser /> Membership
//                             </li>
//                         </Link>
//                         <Link to="/MyProfile">
//                             <li className={activeItem === 'MyProfile' ? 'active' : ''} onClick={() => handleItemClick('MyProfile')}>
//                                 <AiOutlineProfile /> My Profile
//                             </li>
//                         </Link>
//                         <li className={activeItem === 'Logout' ? 'active' : ''} onClick={() => handleItemClick('Logout')}>
//                             <AiOutlineLogout /> Logout
//                         </li>
//                     </ul>
//                 </div>
//             </div>
//         </section>
//     )
// }

// export default SideBar;

import React, { useState } from 'react';
import "./SideBar.css";
import RealiztyLogoLight from "../../Assets/RealiztyLogoLight.png";
import { AiOutlineLineChart, AiOutlineHome, AiOutlineProfile, AiOutlineLogout, AiOutlineUnorderedList, AiOutlineUser } from 'react-icons/ai';
import { MdOutlineDashboard } from "react-icons/md";
import { MdOutlineLeaderboard } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from 'react-router-dom';

function SideBar() {
    const [showPropertiesOptions, setShowPropertiesOptions] = useState(false);
    const [showActivities, setShowActivities] = useState(false);
    const [activeItem, setActiveItem] = useState('');

    const handlePropertiesClick = () => {
        setShowPropertiesOptions(!showPropertiesOptions);
    };

    const handleActivitiesClick = () => {
        setShowActivities(!showActivities);
    }

    const handleItemClick = (itemName) => {
        setActiveItem(itemName);
    }

    return (
        <section className='SideBarMainDiv'>
            <div className='SideBarInnerDiv' style={{ margin: '20px' }}>
                <div className='SidebarRealiztyLogoContainer'>
                    <Link to="/"> <img src={RealiztyLogoLight} alt="Realizty Logo" /> </Link>
                </div>
                <div className='SidebarListItems'>
                    <ul>
                        <li className={activeItem === 'Dashboard' ? 'active' : ''} onClick={() => handleItemClick('Dashboard')}>
                            <MdOutlineDashboard /> <Link to="/Dashboard"> Dashboard </Link> <MdKeyboardArrowRight onClick={handleActivitiesClick} />
                        </li>
                        {showActivities && (
                            <>
                                <Link to="/notifications">
                                    <li className={activeItem === 'Updates' ? 'active' : ''} onClick={() => handleItemClick('Updates')} style={{ padding: "10px", marginLeft: "3rem" }}>Updates</li>
                                </Link>
                            </>
                        )}
                        <Link to="/Leads">
                            <li className={activeItem === 'Leads' ? 'active' : ''} onClick={() => handleItemClick('Leads')}>
                                <MdOutlineLeaderboard /> Leads
                            </li>
                        </Link>
                        <Link to="/Insights">
                            <li className={activeItem === 'Overview' ? 'active' : ''} onClick={() => handleItemClick('Overview')}>
                                <AiOutlineLineChart /> Overview
                            </li>
                        </Link>
                        <li className={activeItem === 'Properties' ? 'active' : ''} onClick={() => handleItemClick('Properties')}>
                            <AiOutlineHome /> <Link to="/Properties"> Properties </Link>  <MdKeyboardArrowRight onClick={handlePropertiesClick} />
                        </li>
                        {showPropertiesOptions && (
                            <>
                                <li className={activeItem === "All" ? 'active' : ''} onClick={() => handleItemClick("All")} style={{ padding: "10px", marginLeft: "3rem" }}>All</li>
                                <li className={activeItem === "Published" ? 'active' : ''} onClick={() => handleItemClick("Published")} style={{ padding: "10px", marginLeft: "3rem" }}>Published</li>
                                <li className={activeItem === "Pending" ? 'active' : ''} onClick={() => handleItemClick("Pending")} style={{ padding: "10px", marginLeft: "3rem" }}>Pending</li>
                                <li className={activeItem === "Expired" ? 'active' : ''} onClick={() => handleItemClick("Expired")} style={{ padding: "10px", marginLeft: "3rem" }}>Expired</li>
                                <li className={activeItem === "Draft" ? 'active' : ''} onClick={() => handleItemClick("Draft")} style={{ padding: "10px", marginLeft: "3rem" }}>Draft</li>
                                <li className={activeItem === "OnHold" ? 'active' : ''} onClick={() => handleItemClick("PublisOnHoldhed")} style={{ padding: "10px", marginLeft: "3rem" }}>OnHold</li>
                                <li className={activeItem === "Disapproved" ? 'active' : ''} onClick={() => handleItemClick("Disapproved")} style={{ padding: "10px", marginLeft: "3rem" }}>Disapproved</li>
                            </>
                        )}
                        <Link to="/CreateListings">
                            <li className={activeItem === 'CreateListing' ? 'active' : ''} onClick={() => handleItemClick('CreateListing')}>
                                <AiOutlineUnorderedList /> Create a Listing
                            </li>
                        </Link>
                        <Link to="/MemberShip">
                            <li className={activeItem === 'Membership' ? 'active' : ''} onClick={() => handleItemClick('Membership')}>
                                <AiOutlineUser /> Membership
                            </li>
                        </Link>
                        <Link to="/MyProfile">
                            <li className={activeItem === 'MyProfile' ? 'active' : ''} onClick={() => handleItemClick('MyProfile')}>
                                <AiOutlineProfile /> My Profile
                            </li>
                        </Link>
                        <li className={activeItem === 'Logout' ? 'active' : ''} onClick={() => handleItemClick('Logout')}>
                            <AiOutlineLogout /> Logout
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default SideBar;
