import React from 'react'
import "./Sellers.css";

function Sellers() {
  return (
    <div className='MainSellers'>
      <h1>Sellers</h1>
    </div>
  )
}

export default Sellers;
