import React, {useState} from 'react'
import "./UsNavbar.css";
import { TbWorldSearch } from "react-icons/tb";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { IoMdPricetags } from "react-icons/io";
import { IoIosBed } from "react-icons/io";
import { FaShower } from "react-icons/fa";
import { FaHome } from "react-icons/fa";
import { GrSettingsOption } from "react-icons/gr";


function UsNavbar() {
    const [isClicked, setIsClicked] = useState(false);

    const handleInputClick = () => {
        setIsClicked(true);
    };
    return (
        <div className='US'>
            <div className='Nav'>
                <div className='SearchInput'>
                    <input type="text" placeholder='NewYork City' className={isClicked ? 'input-clicked' : 'input-normal'}
                        onClick={handleInputClick} />
                    <MdOutlineKeyboardArrowDown className='DownArrowIcon' />
                    <TbWorldSearch className='searchIcon' />
                </div>
                <div className='Price'>
                    <IoMdPricetags className='PriceTagIcon' />
                    <p>Price</p>
                    <MdOutlineKeyboardArrowDown className='DownArrowIcon' />
                    <ul>
                        <li style={{ borderBottom: "1px solid #DEE5ED" }}>Price 1</li>
                        <li>Price 2</li>
                    </ul>
                </div>
                <div className='Bedroom'>
                    <IoIosBed className='BedroomIcon' />
                    <p>Bedrooms</p>
                    <MdOutlineKeyboardArrowDown className='DownArrowIcon' />
                </div>
                <div className='Baths'>
                    <FaShower className='BathIcon' />
                    <p>Baths</p>
                    <MdOutlineKeyboardArrowDown className='DownArrowIcon' />
                </div>
                <div className='Property-Type'>
                    <FaHome className='HomeIcon' />
                    <p>Property Type</p>
                    <MdOutlineKeyboardArrowDown className='DownArrowIcon' />
                </div>
                <div className='Advanced'>
                    <GrSettingsOption className='Setting' />
                    <p>Advanced</p>
                    <MdOutlineKeyboardArrowDown />
                </div>
            </div>
        </div>
    )
}

export default UsNavbar;
