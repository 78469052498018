// //Routes.js
import Layout from "../Layout/Layout";
import MainLayout from "../MainLayout/MainLayout";
import About from "../Pages/About/About";
import Home from "../Pages/Home/Home";
import PropertiesCA from "../Pages/Properties/PropertiesCA";
import PropertiesUS from "../Pages/Properties/PropertiesUS";
import MainListingUs from "../Components/Carousel/MainListingUS/MainListingUs";
import MainGridUS from "../Components/Carousel/MainGridUS/MainGridUS";
import USLayout from "../USLayout/USLayout";
import CALayout from "../CALayout/CALayout";
import MainImageCA from "../Components/Carousel/MainGridCA/MainGridCA";
import MainListingCA from "../Components/Carousel/MainListingCA/MainListingCA";
import Sellers from "../Pages/Sellers/Sellers";
import Favorite from "../Pages/Favorite/Favorite";
import RecentPropertyDetails from "../Pages/RecentPropertyDetails/RecentPropertyDetails";
import AgentListings from "../Components/AgentListings/AgentListings";
import Dashboard from "../Dashboard/Dashboard";
import MyProfile from "../Dashboard/MyProfile/MyProfile"
import DashboardLayout from "../DashboardLayout/DashboardLayout"
import Leads from "../Dashboard/Leads/Leads";
import Insights from "../Dashboard/Insights/Insights";
import Properties from "../Dashboard/Properties/Properties";
import CreateListings from "../Dashboard/CreateListings/CreateListings";
import MemberShip from "../Dashboard/MemberShip/MemberShip";
import CreatelistingsPage2 from "../Dashboard/CreateListings/CreateListingsPage2/CreatelistingsPage2";
import CreateListingsPage3 from "../Dashboard/CreateListings/CreateListingsPage3/CreateListingsPage3"
import CreateListingsPage4 from "../Dashboard/CreateListings/CreateListingsPage4/CreateListingsPage4";
import CreateListingsPage5 from "../Dashboard/CreateListings/CreateListingsPage5/CreateListingsPage5";
import CreateListingsPage6 from "../Dashboard/CreateListings/CreateListingsPage6/CreateListingsPage6";
import NotificationDetails from "../Dashboard/NotificationsDetails/NotificationDetails";

const Router = [
    {
        path: "/",
        element: <MainLayout />,
        children: [
            {
                path: "Home",
                element: <Home />
            },
        ],
    },
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                path: "About",
                element: <About />
            },
            {
                path: "Properties-US",
                element: <PropertiesUS />
            },
            {
                path: "Properties-CA",
                element: <PropertiesCA />
            },
            {
                path: "For-Sellers",
                element: <Sellers />
            },
            {
                path: "Favorites",
                element: <Favorite />
            },
            {
                path: "Properties-US/Property-Details",
                element: <RecentPropertyDetails />,
            },
            {
                path: "AgentListing",
                element: <AgentListings />
            }
        ]
    },
    {
        path: "/Properties-US",
        element: <USLayout />,
        children: [
            {
                path: "ListView",
                element: <MainListingUs />
            },
            {
                path: "GridView",
                element: <MainGridUS />
            },
        ]
    },

    {
        path: "/Properties-CA",
        element: <CALayout />,
        children: [
            {
                path: "ListView",
                element: <MainListingCA />
            },
            {
                path: "GridView",
                element: <MainImageCA />
            }
        ]
    },
    {
        path: "/",
        element: <DashboardLayout />,
        children: [
            {
                path: "Dashboard",
                element: <Dashboard />
            },
            {
                path: "MyProfile",
                element: <MyProfile />
            },
            {
                path: "Leads",
                element: <Leads />
            },
            {
                path: "Insights",
                element: <Insights />
            },
            {
                path: "Properties",
                element: <Properties />
            },
            {
                path: "CreateListings",
                element: <CreateListings />,
            },
            {
                path: "CreateListings-Page2",
                element: <CreatelistingsPage2/>
            },
            {
                path: "CreateListings-Page3",
                element: <CreateListingsPage3/>
            },
            {
                path: "CreateListings-Page4",
                element: <CreateListingsPage4/>
            },
            {
                path: "CreateListings-Page5",
                element: <CreateListingsPage5/>
            },
            {
                path: "/CreateListings-Page6",
                element: <CreateListingsPage6/>
            },
            {
                path: "MemberShip",
                element: <MemberShip />
            },
            {
                path: "notifications",
                element: <NotificationDetails/>
            }
        ]
    },
];

export default Router;