import React, { useState } from 'react';
import "./RecentScheduleTourDetails.css";
import USIMG from "../../Assets/USIMG.jpg";
import { IoChevronBack } from "react-icons/io5";
import { IoChevronForward } from "react-icons/io5";

function RecentScheduleTourDetails() {
    const [slideIndex, setSlideIndex] = useState(0);
    const [isClicked, setIsClicked] = useState(false);

    const handleInputClick = () => {
        setIsClicked(true);
    };

    const handleSlide = (direction) => {
        if (direction === 'left' && slideIndex > 0) {
            setSlideIndex(slideIndex - 1);
        } else if (direction === 'right' && slideIndex < 4) {
            setSlideIndex(slideIndex + 1);
        }
    };

    return (
        <section className='MainDivScheduleTourDetails'>
            <div className='RecentScheduleTourInnerDiv'>
                <div className='ImageContainerScheduleTourDetails'>
                    <img src={USIMG} alt="Apartment" />
                </div>
                <div className='ScheduleTourDiv'>
                    <p>Schedule a Tour</p>
                    <div className='property-schedule-tour-day-form-slide-wrap'>
                        <IoChevronBack className='LeftScheduleArrow' onClick={() => handleSlide('left')} />
                        <div className='property-schedule-tour-day-form-slide' style={{ transform: `translateX(${-slideIndex * 24}rem)` }}>
                            {[...Array(8)].map((_, index) => (
                                <div className='ScheduleTourByPlan' key={index}>
                                    <p className='weekDays' style={{ margin: 0 }}>
                                        {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][index % 7]}
                                    </p>
                                    <p className='monthlyDate' style={{ margin: 0, fontWeight: "bold" }}>0{index + 1}</p>
                                    <p className='MonthName' style={{ margin: 0 }}>April</p>
                                </div>
                            ))}
                        </div>
                        <IoChevronForward className='RightScheduleArrow' onClick={() => handleSlide('right')} />
                    </div>
                    <p className='TourType' style={{ marginTop: "10px" }}>Tour Type</p>
                    <div className='InPersonVideoChatBtn'>
                        <button>In Person</button>
                        <button>Video Chat</button>
                    </div>
                    <div className='FieldsScheduleTour'>
                        <input type="Time" placeholder='Time' className={isClicked ? 'input-clicked' : 'input-normal'}
                                onClick={handleInputClick} />
                        <input type="text" placeholder='Name' className={isClicked ? 'input-clicked' : 'input-normal'}
                                onClick={handleInputClick} />
                        <input type="Number" placeholder='Phone' className={isClicked ? 'input-clicked' : 'input-normal'}
                                onClick={handleInputClick} />
                        <input type="Email" placeholder='Email' className={isClicked ? 'input-clicked' : 'input-normal'}
                                onClick={handleInputClick} />
                        <textarea cols="45" rows="8" placeholder='Enter Your Message' className={isClicked ? 'input-clicked' : 'input-normal'}
                                onClick={handleInputClick}></textarea>
                        <div className='CheckBoxScheduleTour'>
                            <input type="checkbox" />
                            <p>By submitting this form I agree to
                                <span style={{ color: "#00aeff" }}> Terms of Use</span> </p>
                        </div>
                    </div>
                    <div className='SubmitTourBtn'>
                        <button>Submit a Tour Request</button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RecentScheduleTourDetails;
