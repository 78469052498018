import React from 'react'
import "./MemberShip.css";

function MemberShip() {
  return (
    <section className='MemberShipMainDiv'>
      <div className='MemberShipInnerDiv' >
        <p>MemberShip</p>
      </div>

      <div className='PackagesDivSection'>
        <div className='PackagesInnerDiv'>
          <p>Current Package</p>
          <p style= {{fontWeight: "bold"}}>INFINITY PRO (Life-time)</p>
        </div>
      </div>

      <div className='ChangeMemberShipPlanBtnContainer'>
        <button>Change Membership Plan</button>
      </div>
    </section>
  )
}

export default MemberShip;
