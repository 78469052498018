import React from 'react'
import "./RecentOverviewDetails.css";
import { MdOutlineBed } from "react-icons/md";
import { MdOutlineSquareFoot } from "react-icons/md";
import { LiaShowerSolid } from "react-icons/lia";
import { SlCalender } from "react-icons/sl";
function RecentOverviewDetails() {
    return (
        <section className='MainDivOverviewDetails'>
            <div className='RecentOverviewInnerDiv'>
                <div className='PropertyIDOverview'>
                <p className='OverviewText'>Overview</p>
                <p> <span style={{fontWeight: "bold"}}>PropertyID:</span> 123456</p>
                </div>
                
                <div className='ApartmentDetails'>
                    <div className='PropertyTypeRecentOverview'>
                        <p className='SingleFamilyText'>Single Family Home</p>
                        <p>Property Type</p>
                    </div>
                    <div className='FeatureDetailsRecentOverview'>
                        <div className='BedroomsDiv'>
                            <div className='NumOfBeds'>
                                <MdOutlineBed />
                                <p style = {{fontWeight: "bold"}}>3</p>
                            </div>
                            <p className='BedroomsTextOverview'>Bedrooms</p>
                        </div>
                        <div className='ShowerDiv'>
                            <div className='NumOfShower'>
                                <LiaShowerSolid />
                                <p style = {{fontWeight: "bold"}}>3</p>
                            </div>
                            <p className='BathroomsTextOverview'>Bathrooms</p>
                        </div>
                        <div className='SquareFootDiv'>
                            <div className='NumOfSqft'>
                                <MdOutlineSquareFoot />
                                <p style = {{fontWeight: "bold"}}>300</p>
                            </div>
                            <p className='SqftTextOverview'>Sqft</p>
                        </div>
                        <div className='BuiltYearsDiv'>
                            <div className='NumOfYears'>
                                <SlCalender className='SiCalender' />
                                <p style = {{fontWeight: "bold"}}>2013</p>
                            </div>
                            <p className='YearBuiltTextOverview'>Year built</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RecentOverviewDetails;
