import React from 'react'
import Layout from '../Layout/Layout';
import CANavbar from '../Components/CANavbar/CANavbar';
import CAHeading from '../Components/CAHeading/CAHeading';
import CAListingNavbar from '../Components/CAListingNavbar/CAListingNavbar';

function CALayout() {
  return (
    <div>
      <Layout />
      <CANavbar />
      <CAHeading />
      <CAListingNavbar />
    </div>
  )
}

export default CALayout;
