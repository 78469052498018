import React from 'react'
import CAListing from "../CAListing/CAListing";
import "./MainListingCA.css";

function MainListingCA() {
   const listings = [1, 2, 3, 4, 5, 6];
  return (
    <div className='maindivCAList'>
            <div className='contentCAList'>
                <div className='sliderCAList'>
                    {listings.map((index) => (
                        <CAListing key={index} />
                    ))}
                </div>
            </div>
            <div className='ButtonConatinerCAList'>
                <button>Load More</button>
            </div>
        </div>
  )
}

export default MainListingCA;
