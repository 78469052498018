import React from 'react';
import "./MainGridCA.css";
import CAProperties from '../CAProperties/CAProperties';

function MainImageCA() {
    const numberOfSliders = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    return (
        <div className='maindivCA'>
            <div className='contentCA'>
                <div className='sliderCA'>
                    {numberOfSliders.map((index) => (
                        <CAProperties key={index} />
                    ))}
                </div>
            </div>
            <div className='ButtonConatinerCA'>
                <button>Load More</button>
            </div>
        </div>
    );
}

export default MainImageCA;