import React from 'react'
import "./NotificationDetails.css";
import { IoPersonSharp } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";
import { IoInformation } from "react-icons/io5";
import { FaCheck } from "react-icons/fa";

function NotificationDetails() {
    return (
        <section className='NotificationsMainPageSection'>
            <div className='ActivitiesHeaderSection'>
                <p style = {{fontWeight: 'bold'}} >Updates</p>
            </div>
            <div className='NotificationsCards'>

                <div className='NotificationCard1'>
                    <div className='NotificationPersonIconDivSection'>
                        <IoPersonSharp style={{ color: "white", fontSize: "35px" }} />
                    </div>
                    <div className='LeadsRecievedNotificationDivSection'>
                        <div className='LeadsRecievedRow1'>
                            <p style={{ margin: 0, fontSize: '15px', marginBottom: "10px" }}>A new buyer lead recieved </p>
                            <p style={{ margin: 0, fontSize: '14px', fontWeight: 'bold' }}>John Doe</p>
                            <p style={{ margin: 0, fontSize: '14px' }}>I'm Buyer</p>
                            <p style={{ margin: 0, fontSize: '14px' }}>0123456789</p>
                        </div>
                        <div className='LeadsRecievedSectionRow2'>
                            <p style={{ margin: 0, fontSize: '14px' }}>email@email.com</p>
                            <p style={{ margin: 0, fontSize: '15px', color: '#B8B8B8' }}>Today, 6:01pm</p>
                        </div>
                        <div className='LeadsRecievedSectionRow3'>
                            <p style={{ margin: 0, fontSize: '15px' }} >Hello, I'm interested in this listing</p>
                        </div>
                    </div>
                    <div className='NotificationSectionArrowIconDiv'>
                        <IoIosArrowForward style={{ fontSize: '2rem' }} />
                    </div>
                </div>

                <div className='NotificationCard2'>
                    <div className='NotificationCheckIconDivSection'>
                        <FaCheck style={{ color: "white", fontSize: "35px" }} />
                    </div>
                    <div className='ListingsRecievedNotificationDivSection'>
                        <div className='ListingsRecievedRow1'>
                            <p style={{ margin: 0, fontSize: '15px', marginBottom: "10px" }}>Your listing has been approved </p>
                            <p style={{ margin: 0, fontSize: '14px', fontWeight: 'bold' }}>CUSTOM POOL HOME W/ SPECT</p>
                            <p style={{ margin: 0, fontSize: '14px' }}>I'm Buyer</p>
                            <p style={{ margin: 0, fontSize: '14px' }}>0123456789</p>
                        </div>
                        <div className='ListingsRecievedSectionRow2'>
                            <p style={{ margin: 0, fontSize: '14px' }}>email@email.com</p>
                            <p style={{ margin: 0, fontSize: '15px', color: '#B8B8B8' }}>Yesterday, 6:01pm</p>
                        </div>
                        <div className='ListingsRecievedSectionRow3'>
                            <p style={{ margin: 0, fontSize: '15px' }} >Hello, I'm interested in this listing</p>
                        </div>
                    </div>
                    <div className='NotificationSectionArrowIconDiv'>
                        <IoIosArrowForward style={{ fontSize: '2rem' }} />
                    </div>
                </div>

                <div className='NotificationCard3'>
                    <div className='NotificationInformationIconDivSection'>
                        <IoInformation style={{ color: "black", fontSize: "35px" }} />
                    </div>
                    <div className='ListingsExpiredRecievedNotificationDivSection'>
                        <div className='ListingsExpiredRecievedRow1'>
                            <p style={{ margin: 0, fontSize: '15px', marginBottom: "10px" }}>Your listing is expiring soon </p>
                            <p style={{ margin: 0, fontSize: '14px', fontWeight: 'bold' }}>1064 CAVENDER CREEK RD, MIN</p>
                            <p style={{ margin: 0, fontSize: '14px' }}>I'm Buyer</p>
                            <p style={{ margin: 0, fontSize: '14px' }}>0123456789</p>
                        </div>
                        <div className='ListingsExpiredRecievedSectionRow2'>
                            <p style={{ margin: 0, fontSize: '14px' }}>email@email.com</p>
                            <p style={{ margin: 0, fontSize: '15px', color: '#B8B8B8' }}>05/07/2024, 12:10pm</p>
                        </div>
                        <div className='ListingsExpiredRecievedSectionRow3'>
                            <p style={{ margin: 0, fontSize: '15px' }} >Hello, I'm interested in this listing</p>
                        </div>
                    </div>
                    <div className='NotificationSectionArrowIconDiv'>
                        <IoIosArrowForward style={{ fontSize: '2rem' }} />
                    </div>
                </div>
                <div className='NotificationCard3'>
                    <div className='NotificationInformationIconDivSection'>
                        <IoInformation style={{ color: "black", fontSize: "35px" }} />
                    </div>
                    <div className='ListingsExpiredRecievedNotificationDivSection'>
                        <div className='ListingsExpiredRecievedRow1'>
                            <p style={{ margin: 0, fontSize: '15px', marginBottom: "10px" }}>Your listing is expiring soon </p>
                            <p style={{ margin: 0, fontSize: '14px', fontWeight: 'bold' }}>1064 CAVENDER CREEK RD, MIN</p>
                            <p style={{ margin: 0, fontSize: '14px' }}>I'm Buyer</p>
                            <p style={{ margin: 0, fontSize: '14px' }}>0123456789</p>
                        </div>
                        <div className='ListingsExpiredRecievedSectionRow2'>
                            <p style={{ margin: 0, fontSize: '14px' }}>email@email.com</p>
                            <p style={{ margin: 0, fontSize: '15px', color: '#B8B8B8' }}>05/07/2024, 12:10pm</p>
                        </div>
                        <div className='ListingsExpiredRecievedSectionRow3'>
                            <p style={{ margin: 0, fontSize: '15px' }} >Hello, I'm interested in this listing</p>
                        </div>
                    </div>
                    <div className='NotificationSectionArrowIconDiv'>
                        <IoIosArrowForward style={{ fontSize: '2rem' }} />
                    </div>
                </div>
                <div className='NotificationCard1'>
                    <div className='NotificationPersonIconDivSection'>
                        <IoPersonSharp style={{ color: "white", fontSize: "35px" }} />
                    </div>
                    <div className='LeadsRecievedNotificationDivSection'>
                        <div className='LeadsRecievedRow1'>
                            <p style={{ margin: 0, fontSize: '15px', marginBottom: "10px" }}>A new buyer lead recieved </p>
                            <p style={{ margin: 0, fontSize: '14px', fontWeight: 'bold' }}>John Doe</p>
                            <p style={{ margin: 0, fontSize: '14px' }}>I'm Buyer</p>
                            <p style={{ margin: 0, fontSize: '14px' }}>0123456789</p>
                        </div>
                        <div className='LeadsRecievedSectionRow2'>
                            <p style={{ margin: 0, fontSize: '14px' }}>email@email.com</p>
                            <p style={{ margin: 0, fontSize: '15px', color: '#B8B8B8' }}>Today, 6:01pm</p>
                        </div>
                        <div className='LeadsRecievedSectionRow3'>
                            <p style={{ margin: 0, fontSize: '15px' }} >Hello, I'm interested in this listing</p>
                        </div>
                    </div>
                    <div className='NotificationSectionArrowIconDiv'>
                        <IoIosArrowForward style={{ fontSize: '2rem' }} />
                    </div>
                </div>

            </div>
        </section>
    )
}

export default NotificationDetails;
