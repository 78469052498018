import React from 'react';
import "./RecentFeaturesDetails.css";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

const features = [
    "Air Conditioning",
    "Dishwasher",
    "Disposal",
    "Dryer",
    "Electric Water Heater",
    "Laundry",
    "Lighting",
    "Microwave",
    "Open Floorplan",
    "Outdoor Shower",
    "Range",
    "Range Hood",
    "Refrigerator",
    "Storage",
    "WalkInCloset",
];

function RecentFeaturesDetails() {
    return (
        <section className='MainDivFeaturesDetails'>
            <div className='RecentFeaturesInnerDiv'>
                <p className='recentFeaturesDetailsFeatureHeading'>Features</p>
                <div className='EntireItems'>
                    {[...Array(3)].map((_, colIndex) => (
                        <div key={colIndex} className={`RecentFeaturesDetailsCol${colIndex + 1}`}>
                            {features.slice(colIndex * 5, (colIndex + 1) * 5).map((feature, index) => (
                                <div key={index} className={feature.split(' ').join('')}>
                                    <IoIosCheckmarkCircleOutline className='IoIosCheckmarkCircleOutline' />
                                    <p className='FeaturesNameText'>{feature}</p>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default RecentFeaturesDetails;
