import React from 'react';
import './RecentVideoDetails.css';

function RecentVideoDetails() {
    return (
        <section className='MainDivVideoDetails'>
            <div className='RecentVideoDetailsInnerDiv'>
                <p className='RecentVideoDetailsHeadingText'>Video</p>
                <div className='RecentVideoDetailsContainer'>
                    <iframe
                        src="https://www.youtube.com/embed/npZZjOeE6mA"
                        allow="fullscreen"
                        title="YouTube Homepage"
                        // frameborder="0"
                    ></iframe>
                </div>
            </div>
        </section>
    );
}

export default RecentVideoDetails;



