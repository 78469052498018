import React from 'react'
import "./AgentListings.css";
import AgentProfileContainer from './AgentProfileContainer/AgentProfileContainer';
import AgentNumOfListings from './AgentNumOfListings/AgentNumOfListings';
import AgentNameListingReviews from './AgentNameListingReviews/AgentNameListingReviews';
import MainAgentListingsDetails from './MainAgentListingsDetails/MainAgentListingsDetails';
import FindAgentDetails from './FindAgentDetails/FindAgentDetails';
function AgentListings() {
  return (
    <div>
        <AgentProfileContainer/>
        <AgentNumOfListings/>
        <AgentNameListingReviews/>
        <MainAgentListingsDetails/>
        <FindAgentDetails/>
    </div>
  )
}

export default AgentListings;


