import React, {useState} from 'react'
import "./ImageSliderFlorida.css";
import BGIMG from "../../../Assets/BGIMG.jpg";
import BGIMGFlorida from "../../../Assets/BGIMGFlorida.jpg"
import { MdOutlineBed } from "react-icons/md";
import { LiaShowerSolid } from "react-icons/lia";
import { MdSquareFoot } from "react-icons/md";
import { GoPerson } from "react-icons/go";
import { PiLinkSimple } from "react-icons/pi";
import { FaCamera } from "react-icons/fa";
import { ImEnlarge2 } from "react-icons/im";
import { MdCompare } from "react-icons/md";
import { MdFavorite } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

function ImageSliderFlorida() {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [BGIMG, BGIMGFlorida,];

  const nextImage = () => {
    setCurrentImage((currentImage + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImage((currentImage - 1 + images.length) % images.length);
  };


  return (
    <div className='ContainerFlorida'>
      <div className='ImageMain'>
        <div className='Image'>
          <IoIosArrowBack className="backIcon" onClick={prevImage} />
          <img src={images[currentImage]} alt="Apartment" />
          <IoIosArrowForward className='forwardIcon' onClick={nextImage} />
        </div>
        <div className="Tags">
          <div className="SaleTag">
            <p>For Selling the property  </p>
          </div>
          <div className="RentTag">
            <p>For Rent</p>
          </div>
        </div>
        <div className='ImageItems'>
          <div className='TextPriceFlorida'>
            <p>$1,000</p>
          </div>
          <div className='IconsFLorida'>
            <FaCamera />
            <ImEnlarge2 />
            <MdCompare />
            <MdFavorite />
          </div>
        </div>
      </div>
      <div className='Location'>
        <p style={{ color: "black" }}>123456 AVENUE Canada , Colombia, British, XYZ</p>
        <p style={{ margin: 0 }}>14555, 78 AVENUE</p>
      </div>
      <div className='FeatureConatiner'>
        <div className='Features'>
          <div className='Bedrooms'>
            <MdOutlineBed />
            <p>5</p>
          </div>
          <div className='Shower'>
            <LiaShowerSolid />
            <p>5</p>
          </div>
          <div className='SquareFoot'>
            <MdSquareFoot />
            <p>3762 Sqft</p>
          </div>
        </div>
        <div className='Btn_Container'>
          <button type="button">Details</button>
        </div>
      </div>
      <div className='PropertyHeading'>
        <p style={{ margin: 0 }}>RESIDENTIAL</p>
      </div>

      <div className='Details'>
        <div className='Agent'>
          <GoPerson />
          <p>Tom Miller</p>
        </div>
        <div className='Posted'>
          <PiLinkSimple />
          <p>1 week ago</p>
        </div>
      </div>
    </div>
  )
}

export default ImageSliderFlorida;
