import React, { useState } from 'react'
import "./header.css";
import { FiPhoneCall } from "react-icons/fi";
// import color from '../../Utils/Color';
import { RiArrowDropDownLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { TiThMenu } from "react-icons/ti";
import RealiztyLogo from "../../Assets/RealiztyLogo.png";
import Modal from "../../Modal/Modal";

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isLoginOpen, setIsLoginOpen] = useState(false);

    const toggleLoginDialog = () => {
        setIsLoginOpen(!isLoginOpen);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        
    };
    return (
        <section className='header_main' >
            <div className='MenuDiv'>
                <div className='MenuIcon' onClick={toggleMenu}>
                    <TiThMenu />
                </div>
                {isMenuOpen && (
                    <div className='MenuItems'>
                        <ul>
                            <Link to="Home" className='HomeLink'> <li>Home</li></Link>
                            <Link to="About" className='AboutLink'> <li>About</li></Link>
                            <li>Properties</li>
                            <ul className="subMenu">
                                <Link to="Properties-US" className='USLink' > <li>Properties-US</li></Link>
                                <Link to="Properties-CA" className='CALink' ><li>Properties-CA</li></Link>
                            </ul>
                            <Link to="For-Sellers" className='SellerLink' ><li>Sellers</li></Link>
                            <li>Realtor</li>
                            <ul> 
                                <li>Connect with an Agent</li>
                            </ul>
                        </ul>
                    </div>
                )}
            </div>

            <div className='logo'>
                <img src = {RealiztyLogo} alt="RealiztyLogo" />
            </div>
            <div className='items' >
                <Link to="/Home" className='HomeLink'><p className='home'>HOME</p></Link>
                <Link to="/About" className='AboutLink'><p className='about'>ABOUT</p></Link>
                <div className='Liproperties'>
                    <p className='properties'>PROPERTIES</p>
                    <RiArrowDropDownLine className='downArrowIcon' />
                    <ul>
                        <Link to="/Properties-US" className='USLink'>
                            <li>Properties US</li>
                        </Link>
                        <Link to="/Properties-CA" className='CALink'>
                            <li>Properties Canada</li>
                        </Link>
                    </ul>
                </div>
                <Link to="/For-Sellers" className='SellerLink'>  <p className='sellers'>FOR SELLERS</p> </Link>
                <div className='realtor'>
                    <p className='realtors'>REALTORS</p>
                    <RiArrowDropDownLine className='downArrowIcon' />
                    <ul>
                        <li>Connect with an Agent</li>
                    </ul>
                </div>
            </div>
            <div className='contact'>
                <div className='phone'>
                    <FiPhoneCall className='phonecallIcon' />
                    <p className='number'>+1(855)743-1091</p>
                </div>
                <button onClick={toggleLoginDialog}>SIGN IN</button>
            </div>
            {isLoginOpen && <Modal handleClose={toggleLoginDialog} />}
            {/* <div className='Favorites'>
              <Link to = "Favorites" className='Favorite'> <p>Favorite</p> </Link>  
            </div> */}
        </section>
    )
}

export default Header;
