import React from 'react';
import "./CreateListingsPage4.css";
import { GrFormNextLink } from "react-icons/gr";
import { Link } from 'react-router-dom';

function CreateListingsPage4() {
    const features = [
        ["Air conditioning", "Cathedral Ceiling(s)", "Dryer", "Fence", "Ice Maker", "Laundry", "Microwave", "Rain Gutters", "Sauna", "Solid Wood Cabinets", "Swimming Pool", "Vaulted Ceiling(s)", "Window Coverings"],
        ["Balcony", "Ceiling Fans(s)", "Eat-in Kitchen", "Freezer", "Irrigation System", "Lawn", "Open Floorplan", "Range", "Sidewalk", "Split Bedroom", "Tennis Court(s)", "Walk-In Closet(s)", "Window Treatments"],
        ["Barbeque", "Dishwasher", "Electric Water Heater", "Gym", "Kitchen/Family Room Combo", "Lighting", "Outdoor Grill", "Range Hood", "Sliding Doors", "Stone Counters", "Thermostat", "Washer"],
        ["Built-In Oven", "Disposal", "Elevator", "High Ceilings", "L Dining", "Living Room/Dining Room Combo", "Outdoor Shower", "Refrigerator", "Solid Surface Counters", "Storage", "TV Cable", "WiFi(s)"]
    ];

    return (
        <section className='CreateListingsPage4MainDiv'>
            <div className='CreateListingsPage4SectionInnerDiv'>
                <div className='CreateListingsPage4HeaderTextBtn'>
                    <p>Create Listings</p>
                    <button>Save as Draft</button>
                </div>
            </div>

            <div className='CreateListingsMainFeaturesDiv'>
                {features.map((row, index) => (
                    <div key={index} className={`RowFeatures RowFeatures${index + 1}`}>
                        {row.map((feature, idx) => (
                            <div className='FeaturesMapDiv' key={idx}>
                                <input type="checkbox" />
                                <p>{feature}</p>
                            </div>
                        ))}
                    </div>
                ))}
            </div>

            <div className='CreateListingsPage4Footer'>
                <div className='CreateListingsPage4BtnContainerFooter'>
                    <div className='CreateListingsPage4BackBtnIconContainer'>
                        <Link to="/CreateListings-Page3">  <button>Back</button>  </Link>
                    </div>
                    <div className='CreateListingsPage4NextBtnIconContainer'>
                        <Link to = "/CreateListings-Page5" className='NextBtnLink'>  <button>Next
                            <GrFormNextLink style={{ fontSize: '30px', marginLeft: "10px" }} />
                        </button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CreateListingsPage4;
