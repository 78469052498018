// Filename - components/Footer.js

import React from "react";
import {
	Box,
	FooterContainer,
	Row,
	Column,
	Heading,
} from "./FooterStyles";

const Footer = () => {
	return (
		<Box>
			<h1
				style={{
					color: "white",
					textAlign: "center",
					marginTop: "10px",
				}}
			>
			Realizty
			</h1>
			<FooterContainer>
				<Row>
					<Column>
						<Heading>About Us</Heading>

					</Column>
					<Column>
						<Heading>Services</Heading>

					</Column>
					<Column>
						<Heading>Contact Us</Heading>
					</Column>
					<Column>
						<Heading>Social Media</Heading>

					</Column>
				</Row>
			</FooterContainer>
		</Box>
	);
};
export default Footer;
