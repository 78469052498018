import React from 'react'
import SideBar from '../Dashboard/SideBar/SideBar'
import { Outlet } from 'react-router-dom'
function DashboardLayout() {
    return (
        <div>
            <SideBar />
            <Outlet />
        </div>
    )
}

export default DashboardLayout
