import React, { useState } from 'react';
import "./MortageCalculator.css";
import { FaRegCircle } from "react-icons/fa";
import { CgDollar } from "react-icons/cg";
import { AiOutlinePercentage } from "react-icons/ai";
import { SlCalender } from "react-icons/sl";
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";

ChartJS.register(ArcElement, Tooltip);

function MortageCalculator() {
    const [isClicked, setIsClicked] = useState(false);

    const [totalAmount, setTotalAmount] = useState('');
    const [downPayment, setDownPayment] = useState('');
    const [interestRate, setInterestRate] = useState('');
    const [loanTerm, setLoanTerm] = useState('');
    const [propertyTax, setPropertyTax] = useState(0);
    const [homeInsurance, setHomeInsurance] = useState(0);
    const [pmi, setPmi] = useState(0);
    const [monthlyMortgage, setMonthlyMortgage] = useState(0);
    const [principleInterest, setPrincipleInterest] = useState(0);

    const data = {
        labels: ['Principle Interest', 'Property Tax', 'Home Insurance', 'PMI'],
        datasets: [
            {
                data: [principleInterest, propertyTax, homeInsurance, pmi],
                backgroundColor: ['#FF9EB3', '#9AD0F5', '#FFE299', '#90E6E6'],
                hoverBackgroundColor: ['#FF9EB3', '#9AD0F5', '#FFE299', '#90E6E6'],
            },
        ],
    };


    const handleInputClick = () => {
        setIsClicked(true);
    };

    const calculateCompoundInterestWithFormula = (P, r, n) => {
        return P * r * (Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1);
    };

    const calculateMortgage = () => {

        // Convert string inputs to numbers
        const inputTotalPayment = parseFloat(totalAmount);
        const inputDownPaymentPercentage = parseFloat(downPayment); // User input in percent
        const downPaymentAmount = inputTotalPayment * (inputDownPaymentPercentage / 100);
        const principal = inputTotalPayment - downPaymentAmount;
        const inputRate = parseFloat(interestRate);
        const rate = (inputRate / 100) / 12; // Annual interest rate
        const inputPaymentYears = parseFloat(loanTerm);
        const years = inputPaymentYears * 12; // Number of payments over the loan’s lifetime
        const resultWithFormula = parseFloat(calculateCompoundInterestWithFormula(principal, rate, years)).toFixed(2);

        // Calculate property tax, home insurance, and PMI
        const inputPropertyTax = parseFloat(propertyTax);
        const propertyTaxMonthly = parseFloat(inputPropertyTax / 12).toFixed(2);
        const inputHomeInsurance = parseFloat(homeInsurance);
        const homeInsuranceMonthly = parseFloat(inputHomeInsurance / 12).toFixed(2);
        const inputPmi = parseFloat(pmi).toFixed(2);


        // Calculate monthly mortgage payment
        const resultMonthlyMortgage = (
            parseFloat(resultWithFormula) +
            parseFloat(propertyTaxMonthly) +
            parseFloat(homeInsuranceMonthly) +
            parseFloat(inputPmi)
        ).toFixed(2);

        setMonthlyMortgage(resultMonthlyMortgage);
        console.log("abc ", resultMonthlyMortgage);

        // Update Principle & Interest, Property Tax, Home Insurance, PMI, and Down Payment
        setPrincipleInterest(resultWithFormula);
        setPropertyTax(propertyTaxMonthly);
        setHomeInsurance(homeInsuranceMonthly);
        setPmi(inputPmi);
        setDownPayment(inputDownPaymentPercentage);

    };


    return (
        <section className='MainDivMortageCalculator'>
            <div className='MortageCalculatorInnerDiv'>
                <p className='MortageCalculatorHeadingText'>Mortage Calculator</p>
                <div className='DonutChartDetails'>
                    <div className='DonutChart'>
                        <p>Monthly Mortgage: <span>${monthlyMortgage}</span> </p>

                        <Doughnut data={data} style= {{marginLeft: "100px", marginTop: "20px"}} />
                    </div>
                    <div className='ChartDetailsValues'>
                        <div className='PrincipleInterest'>
                            <div className='PrincipleInterestIconText'>
                                <FaRegCircle style={{ color: "#FF9EB3" }} />
                                <p style={{ fontWeight: "bold" }}>Principle & Interest</p>
                            </div>
                            <p className='ValuesText'>$ {principleInterest}</p>
                        </div>
                        <div className='PropertyTax'>
                            <div className='PropertyTaxIcoxt'>
                                <FaRegCircle style={{ color: "#9AD0F5" }} />
                                <p style={{ fontWeight: "bold" }}>Property Tax</p>
                            </div>
                            <p className='ValuesText'>$ {propertyTax}  </p>
                        </div>
                        <div className='HomeInsurance'>
                            <div className='HomeInsuranceIconText'>
                                <FaRegCircle style={{ color: "#FFE299" }} />
                                <p style={{ fontWeight: "bold" }}>Home Insurance</p>
                            </div>
                            <p className='ValuesText'>$ {homeInsurance} </p>
                        </div>
                        <div className='PMI'>
                            <div className='PMIIconText'>
                                <FaRegCircle style={{ color: "#90E6E6" }} />
                                <p style={{ fontWeight: "bold" }}>PMI</p>
                            </div>
                            <p className='ValuesText'>$ {pmi} </p>
                        </div>
                    </div>
                </div>
                <div className='CalculatingValues'>
                    <div className='CalculatingValuesRow1'>
                        <div className='TotalAmount'>
                            <p className='ValuePText' >Total Amount</p>
                            <div className='IconInputField' >
                                <div className='MortageCalculatorIcons'>
                                    <CgDollar className='CgDollar' />
                                </div>
                                <input
                                    type="number"
                                    placeholder='Total Amount'
                                    className={isClicked ? 'input-clicked' : 'input-normal'}
                                    onClick={handleInputClick}
                                    onChange={(e) => setTotalAmount(e.target.value)} />
                            </div>
                        </div>
                        <div className='DownPayment'>
                            <p className='ValuePText'>Down Payment</p>
                            <div className='IconInputField'  >
                                <div className='MortageCalculatorIcons'>
                                    <AiOutlinePercentage className='AiOutlinePercentage' />
                                </div>
                                <input
                                    type="number"
                                    placeholder='Down Payment'
                                    className={isClicked ? 'input-clicked' : 'input-normal'}
                                    onClick={handleInputClick}
                                    value={downPayment}
                                    onChange={(e) => setDownPayment(e.target.value)} />
                            </div>
                        </div>
                        <div className='InterestRate'>
                            <p className='ValuePText'>Interest Rate</p>
                            <div className='IconInputField' >
                                <div className='MortageCalculatorIcons'>
                                    <AiOutlinePercentage className='AiOutlinePercentage' />
                                </div>
                                <input type="number" placeholder='Interest Rate' className={isClicked ? 'input-clicked' : 'input-normal'}
                                    onClick={handleInputClick}
                                    onChange={(e) => setInterestRate(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className='CalculatingValuesRow2'>
                        <div className='LoanTerms'>
                            <p className='ValuePText'>Loan Terms (Years)</p>
                            <div className='IconInputField' >
                                <div className='MortageCalculatorIcons'>
                                    <SlCalender className='SlCalender' />
                                </div>
                                <input type="number" placeholder='Loan Terms' className={isClicked ? 'input-clicked' : 'input-normal'}
                                    onClick={handleInputClick} onChange={(e) => setLoanTerm(e.target.value)} />
                            </div>
                        </div>
                        <div className='PropertyTAX'>
                            <p className='ValuePText'>Property Tax</p>
                            <div className='IconInputField'  >
                                <div className='MortageCalculatorIcons'>
                                    <CgDollar className='CgDollar' />
                                </div>
                                <input type="number" placeholder='Property Tax' className={isClicked ? 'input-clicked' : 'input-normal'}
                                    onClick={handleInputClick} onChange={(e) => setPropertyTax(e.target.value)} />
                            </div>
                        </div>
                        <div className='HOMEInsurance'>
                            <p className='ValuePText'>Home Insurance</p>
                            <div className='IconInputField' >
                                <div className='MortageCalculatorIcons'>
                                    <CgDollar className='CgDollar' />
                                </div>
                                <input type="number" placeholder='Home Insurance' className={isClicked ? 'input-clicked' : 'input-normal'}
                                    onClick={handleInputClick} onChange={(e) => setHomeInsurance(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className='CalculatingValuesRow3'>
                        <div className='PMIValue'>
                            <p className='ValuePText' >PMI</p>
                            <div className='IconInputField' >
                                <div className='MortageCalculatorIcons'>
                                    <CgDollar className='CgDollar' />
                                </div>
                                <input type="number" placeholder='PMI' className={isClicked ? 'input-clicked' : 'input-normal'}
                                    onClick={handleInputClick} onChange={(e) => setPmi(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='MortageCalculatorCalculateBtn'>
                    <button onClick={calculateMortgage}>Calculate</button>
                </div>
            </div>
        </section>
    )
}

export default MortageCalculator;

