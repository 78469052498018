import React, {useState} from 'react'
import "./CANavbar.css";
import { TbWorldSearch } from "react-icons/tb";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { IoMdPricetags } from "react-icons/io";
import { IoIosBed } from "react-icons/io";
import { FaShower } from "react-icons/fa";
import { FaHome } from "react-icons/fa";
import { GrSettingsOption } from "react-icons/gr";


function CANavbar() {
    const [isClicked, setIsClicked] = useState(false);

    const handleInputClick = () => {
        setIsClicked(true);
    };
    return (
        <div className='CA'>
            <div className='NavCA'>
                <div className='CASearchInput'>
                    <input type="text" placeholder='NewYork City' className={isClicked ? 'input-clicked' : 'input-normal'}
                        onClick={handleInputClick} />
                    <MdOutlineKeyboardArrowDown className='CADownArrowIcon' />
                    <TbWorldSearch className='CAsearchIcon' />
                </div>
                <div className='CAPrice'>
                    <IoMdPricetags className='CAPriceTagIcon' />
                    <p>Price</p>
                    <MdOutlineKeyboardArrowDown className='CADownArrowIcon' />
                    <ul>
                        <li style={{ borderBottom: "1px solid #DEE5ED" }}>Price 1</li>
                        <li>Price 2</li>
                    </ul>
                </div>
                <div className='CABedroom'>
                    <IoIosBed className='CABedroomIcon' />
                    <p>Bedrooms</p>
                    <MdOutlineKeyboardArrowDown className='CADownArrowIcon' />
                </div>
                <div className='CABaths'>
                    <FaShower className='CABathIcon' />
                    <p>Baths</p>
                    <MdOutlineKeyboardArrowDown className='CADownArrowIcon' />
                </div>
                <div className='CAProperty-Type'>
                    <FaHome className='CAHomeIcon' />
                    <p>Property Type</p>
                    <MdOutlineKeyboardArrowDown className='CADownArrowIcon' />
                </div>
                <div className='CAAdvanced'>
                    <GrSettingsOption />
                    <p>Advanced</p>
                    <MdOutlineKeyboardArrowDown />
                </div>
            </div>
        </div>
    )
}

export default CANavbar;
