import React, { useState } from 'react'
import "./CreateListingsPage3.css";
import { BsList } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { RiAddCircleLine } from "react-icons/ri";
import { GrFormNextLink } from "react-icons/gr";
import { Link } from 'react-router-dom';

function CreateListingsPage3() {

    const [fields, setFields] = useState([{ title: '', value: '' }]);

    const [isClicked, setIsClicked] = useState(false);

    const handleInputClick = () => {
        setIsClicked(true);
    };

    const handleChange = (index, e) => {
        const values = [...fields];
        values[index][e.target.name] = e.target.value;
        setFields(values);
    };

    const handleAddNew = () => {
        setFields([...fields, { title: '', value: '' }]);
    };

    const handleRemove = (index) => {
        const values = [...fields];
        values.splice(index, 1);
        setFields(values);
    };


    return (
        <section className='CreateListingsPage3MainDiv'>
            <div className='CreateListingsPage3SectionInnerDiv' >
                <div className='CreateListingsPage3HeaderTextBtn'>
                    <p>Create Listings</p>
                    <button >Save as Draft</button>
                </div>
            </div>

            <div className='CreateListingsPage3MainFormSection'>

                <div className='Page3InputFieldsRow1'>
                    <div sttyle = {{width: "auto"}}>
                        <p>Bedrooms</p>
                        <input type="number" placeholder='Bedrooms' onClick={handleInputClick}
                            className={isClicked ? 'input-clicked' : 'input-normal'} />
                    </div>
                    <div>
                        <p>Rooms</p>
                        <input type="number" placeholder='Rooms' onClick={handleInputClick}
                            className={isClicked ? 'input-clicked' : 'input-normal'} />
                    </div>
                </div>

                <div className='Page3InputFieldsRow2'>
                    <div>
                        <p>Bathrooms</p>
                        <input type="number" placeholder='Bathrooms' onClick={handleInputClick}
                            className={isClicked ? 'input-clicked' : 'input-normal'} />
                    </div>
                    <div>
                        <p>Area Size</p>
                        <input type="number" placeholder='Area Size' onClick={handleInputClick}
                            className={isClicked ? 'input-clicked' : 'input-normal'} />
                    </div>
                </div>

                <div className='Page3InputFieldsRow3'>
                    <div>
                        <p>Size Postfix</p>
                        <input type="number" placeholder='Size Postfix' onClick={handleInputClick}
                            className={isClicked ? 'input-clicked' : 'input-normal'} />
                    </div>
                    <div>
                        <p>Land Area</p>
                        <input type="number" placeholder='Land Area' onClick={handleInputClick}
                            className={isClicked ? 'input-clicked' : 'input-normal'} />
                    </div>
                </div>

                <div className='Page3InputFieldsRow4'>
                    <div>
                        <p>Land Area Size Postfix</p>
                        <input type="number" placeholder='Land Area Size Postfix' onClick={handleInputClick}
                            className={isClicked ? 'input-clicked' : 'input-normal'} />
                    </div>
                    <div>
                        <p>Garages</p>
                        <input type="number" placeholder='Garages' onClick={handleInputClick}
                            className={isClicked ? 'input-clicked' : 'input-normal'} />
                    </div>
                </div>

                <div className='Page3InputFieldsRow5'>
                    <div>
                        <p>Garage Size</p>
                        <input type="number" placeholder='Garage Size' onClick={handleInputClick}
                            className={isClicked ? 'input-clicked' : 'input-normal'} />
                    </div>
                    <div>
                        <p>Property ID</p>
                        <input type="number" placeholder='Property ID' onClick={handleInputClick}
                            className={isClicked ? 'input-clicked' : 'input-normal'} />
                    </div>
                </div>

                <div className='Page3InputFieldsRow6'>
                    <div>
                        <p>Year Built</p>
                        <input type="number" placeholder='Year Built' onClick={handleInputClick}
                            className={isClicked ? 'input-clicked' : 'input-normal'} />
                    </div>
                    <div>
                        <p>MLS#</p>
                        <input type="number" placeholder='MLS#' onClick={handleInputClick}
                            className={isClicked ? 'input-clicked' : 'input-normal'} />
                    </div>
                </div>

                <div className='Page3InputFieldsRow7'>
                    <div>
                        <p>County</p>
                        <input type="number" placeholder='County' onClick={handleInputClick}
                            className={isClicked ? 'input-clicked' : 'input-normal'} />
                    </div>
                </div>

            </div>

            <div className='CreateListingsPage3TitleValueSection'>
                {fields.map((field, index) => (
                    <div key={index} className='Page3InputFieldsRow8'>
                        <div>
                            <p>Title</p>
                            <input
                                type="text"
                                placeholder='Eg: Equipment'
                                value={field.title}
                                onChange={(e) => handleChange(index, e)}
                                onClick={handleInputClick}
                                className={isClicked ? 'input-clicked' : 'input-normal'}
                            />
                        </div>
                        <div className='Grill-GasDiv'>
                            <p>Value</p>
                            <input
                                type="number"
                                placeholder='Grill-Gas'
                                value={field.value}
                                onChange={(e) => handleChange(index, e)}
                                onClick={handleInputClick}
                                className={isClicked ? 'input-clicked' : 'input-normal'}
                            />
                        </div>

                        <div className='Page3IconsDiv'>
                            <div className='BsList'>
                                <BsList />
                            </div>
                            <div className='RxCross' onClick={() => handleRemove(index)}>
                                <RxCross2 />
                            </div>
                        </div>
                    </div>
                ))}

                <div className='AddNewBtnContainer' >
                    <button onClick={handleAddNew}>
                        <RiAddCircleLine style={{ fontSize: "20px", marginLeft: "10px" }} />
                        Add New
                    </button>
                </div>
            </div>

            <div className='CreateListingsPage3Footer'>
                <div className='CreateListingsPage3BtnContainerFooter'>
                    <div className='CreateListingsPage3BackBtnIconContainer'>
                        <Link to="/CreateListings-Page2">    <button>Back</button>  </Link>
                    </div>
                    <div className='CreateListingsPage3NextBtnIconContainer'>
                        <Link to="/CreateListings-Page4" className='NextbtnLink'>    <button>Next
                            <GrFormNextLink style={{ fontSize: '30px', marginLeft: "10px" }} />
                        </button>
                        </Link>
                    </div>
                </div>
            </div>


        </section>
    )
}

export default CreateListingsPage3;
