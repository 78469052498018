import React from 'react'
import AgentsCard from '../AgentsCard/AgentsCard';
import "./Cards.css";


function Cards() {
    const CardImages = [1, 2, 3, 4, 5, 6, 7, 8]
    return (
        <div className='mainCard'>
            <h3>Meet Our Real Estate Agents</h3>
            <p style={{ textAlign: "center"}}>Our team of real estate experts is always ready to offer a guiding hand in your journey.</p>
                <div className='Cards'>
                    {CardImages.map((index) => (
                        <AgentsCard key={index} />
                    ))}
                </div>
            </div>
    )
}

export default Cards;
