import React, { useState } from 'react'
import "./USListing.css";
import BGIMG from "../../../Assets/BGIMG.jpg";
import USIMG from "../../../Assets/USIMG.jpg";
import { MdOutlineBed } from "react-icons/md";
import { LiaShowerSolid } from "react-icons/lia";
import { MdSquareFoot } from "react-icons/md";
import { GoPerson } from "react-icons/go";
import { PiLinkSimple } from "react-icons/pi";
import { FaCamera } from "react-icons/fa";
import { ImEnlarge2 } from "react-icons/im";
import { MdCompare } from "react-icons/md";
import { MdFavorite } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

function USListing() {
    const [currentImage, setCurrentImage] = useState(0);
    const images = [BGIMG, USIMG,];

    const nextImage = () => {
        setCurrentImage((currentImage + 1) % images.length);
    };

    const prevImage = () => {
        setCurrentImage((currentImage - 1 + images.length) % images.length);
    };

    return (
        <div className='USListMainDiv'>
            <div className='USListRow'>
                <div className='USListImageContainer'>
                    <div className='USListImage'>
                        <IoIosArrowBack className="ListUSArrowBack" onClick={prevImage} />
                        <img src={images[currentImage]} alt="Apartment" />
                        <IoIosArrowForward className='ListUSArrowForward' onClick={nextImage} />
                    </div>
                    <div className='USListImageItems'>
                        <div className='USListIcons'>
                            <FaCamera />
                            <ImEnlarge2 />
                            <MdCompare />
                            <MdFavorite />
                        </div>
                    </div>
                </div>
            </div>
            <div className='USListCol' >
                <div className="USListTags">
                    <div className="USListSaleTag">
                        <p>For Selling the property  </p>
                    </div>
                    <div className="USListRentTag">
                        <p>For Rent</p>
                    </div>
                </div>
                <div className='USListLocation'>
                    <p style={{ color: "black" }}>123456 AVENUE Canada , Colombia, British, XYZ</p>
                    <p style={{ margin: 0 }}>14555, 78 AVENUE</p>
                </div>
                <div className='USListFeatureContainer'>
                    <div className='USListFeatures'>
                        <div className='USListBedrooms'>
                            <MdOutlineBed className='OutlineBed'  />
                            <p>5</p>
                        </div>
                        <div className='USListShower'>
                            <LiaShowerSolid className='ShowerSolid' />
                            <p>5</p>
                        </div>
                        <div className='USListSquarefoot'>
                            <MdSquareFoot className='SquareFootUS' />
                            <p>3762 Sqft</p>
                        </div>
                    </div>
                </div>
                <div className='USListPropertyHeading'>
                    <p style={{ margin: 0 }}>RESIDENTIAL</p>
                </div>

                <div className='USListDetails'>
                    <div className='USListAgent'>
                        <GoPerson className='PersonIcon' />
                        <p style={{ color: "gray" }}>Tom Miller</p>
                    </div>
                    <div className='USListPosted'>
                        <PiLinkSimple className='LinkSimple' />
                        <p style={{ color: "gray" }}>1 week ago</p>
                    </div>
                </div>
            </div>
            <div className='USListPriceNBtn'>
                <div className='USListPriceText'>
                    <p style={{ margin: 0 }}>$1,000</p>
                </div>
                <div className='USListBtnContainer'>
                    <button type="button">Details</button>
                </div>
            </div>
        </div>
    )
}

export default USListing;
