import React, { useEffect } from 'react';
import * as echarts from 'echarts';

const TopPlatformPieChart = () => {
  useEffect(() => {
    const chartDom = document.getElementById('2');
    const myChart = echarts.init(chartDom);
    const option = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        bottom: '0',
        left: 'center',
      },
      series: [
        {
          name: 'Lead Type',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 15,
              fontWeight: 'bold',

            },

          },
          labelLine: {
            show: false
          },

          data: [
            { value: 1048, name: 'Windows' },
            { value: 735, name: 'IOs' },
            { value: 580, name: 'Android' },
            { value: 341, name: 'Others' }
          ],
          itemStyle: {
            color: function (params) {
              const colorList = ['#121318', '#AE1D23', '#DEE5ED', '#0d47a1'];
              return colorList[params.dataIndex];
            }
          },
        }
      ]

    };

    option && myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
  }, []);

  return <div className='TopPlatFormPieChart'  id="2" ></div>;
};

export default TopPlatformPieChart;
