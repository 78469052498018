import React from 'react'
import "./AgentNameListingReviews.css";

function AgentNameListingReviews() {

    return (
        <section className='AgentNameListingReviewsMainDiv'>
            <div className='AgentNameListingReviews'>
                <p style={{margin: 0}}>About Autumn Makin</p>
            </div>
        </section>
    )
}

export default AgentNameListingReviews;
