//App.js
import React from 'react'
//import "./App.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import router from './Routers/Routes';
//import Home from './Pages/Home/Home';

function App() {
   const routers = createBrowserRouter(router);
  return (
    <div>
      <RouterProvider router={routers}/>
    </div>
  )
}

export default App;
