import React, { useState } from 'react';
import './Modal.css';
import { RxCross2 } from "react-icons/rx";
import RealiztyLogoLight from "../Assets/RealiztyLogoLight.png";
import { Link } from 'react-router-dom';

function Modal({ handleClose }) {
    const [isClicked, setIsClicked] = useState(false);

    const handleInputClick = () => {
        setIsClicked(true);
    };


    return (
        <div className="ModalBackground">
            <div className="MainModalDiv">
                <div className='ModalCloseBtnContainer'>
                    <button onClick={handleClose}>
                        <RxCross2 style={{ fontSize: "20px" }} />
                    </button>
                </div>
                <div className='ModalRealiztyLogoContainer'>
                    <img src={RealiztyLogoLight} alt="Realizty" />
                </div>

                {/* <p className='ModalLoginText'>Sign In</p> */}
                <div className='ModalInputFields'>
                    <div className='ModalEmailInputField'>
                        <p className='ModalEmailText'>Email</p>
                        <input type="Email" placeholder='Enter your email'
                            className={isClicked ? 'inputField-clicked' : 'inputField-normal'}
                            onClick={handleInputClick}
                        />
                    </div>
                    <div className='ModalPasswordInputField'>
                        <p className='ModalPasswordText'>Password</p>
                        <input type="Password" placeholder='Enter your password'
                            className={isClicked ? 'inputField-clicked' : 'inputField-normal'}
                            onClick={handleInputClick} />
                    </div>
                </div>
                <div className='ModalLoginBtnContainer'>
                    <Link to="/Dashboard" >  <button>Sign In</button> </Link>
                </div>
                <p className='ModalForgotPassword'>Forgot Your Password?</p>
            </div>
        </div>
    );
}

export default Modal;



// import React, { useState } from 'react';
// import './Modal.css';
// import { RxCross2 } from "react-icons/rx";
// import RealiztyLogoLight from "../Assets/RealiztyLogoLight.png";
// import axios from "axios";

// function Modal({ handleClose }) {

//     const [email, setEmail] = useState();
//     const [password, setPassword] = useState();

//     const handleSubmit = async (e) => {
//         console.log("www", email, password,);
//         e.preventDefault();
//         let data = JSON.stringify({
//             "email": {email},
//             "password": {password}
//         });

//         let config = {
//             method: 'post',
//             maxBodyLength: Infinity,
//             url: 'http://localhost:5000/login',
//             headers: {
//                 'Content-Type': 'application/json'
//             },
//             data: data
//         };

//         axios.request(config)
//             .then((response) => {
//                 console.log(JSON.stringify(response.data));
//             })
//             .catch((error) => {
//                 console.log(error);
//             });

//     };

//     return (
//         <div className="ModalBackground">
//             <div className="MainModalDiv">
//                 <div className='ModalCloseBtnContainer'>
//                     <button onClick={handleClose}>
//                         <RxCross2 style={{ fontSize: "20px" }} />
//                     </button>
//                 </div>
//                 <div className='ModalRealiztyLogoContainer'>
//                     <img src={RealiztyLogoLight} alt="Realizty" />
//                 </div>
//                 <form>
//                     <div className='ModalInputFields'>
//                         <div className='ModalEmailInputField'>
//                             <p className='ModalEmailText'>Email</p>
//                             <input
//                                 type="email"
//                                 name="email"
//                                 value={email}
//                                 onChange={(e) => setEmail(e.target.value)}
//                                 placeholder="Enter your email"
//                             />
//                         </div>
//                         <div className='ModalPasswordInputField'>
//                             <p className='ModalPasswordText'>Password</p>
//                             <input
//                                 type="password"
//                                 name="password"
//                                 value={password}
//                                 onChange={(e) => setPassword(e.target.value)}
//                                 placeholder="Enter your password"
//                             />
//                         </div>
//                     </div>
//                     <div className='ModalLoginBtnContainer'>
//                         <button type="submit" onClick={handleSubmit}>Sign In</button>
//                     </div>
//                 </form>
//                 <p className='ModalForgotPassword'>Forgot Your Password?</p>
//             </div>
//         </div>
//     );
// }

// export default Modal;
