import React from 'react'
import ExploreProperties from '../Explore/ExploreProperties';
import "./ExploreMain.css";

function ExploreMain() {
    const numOfImages = [1, 2, 3, 4, 5] 
    return (
        <div className='MainExplore'>
            <div className='Text'>
                <h6>Explore Properties</h6>
            </div>
            <div className='SliderExplore'>
                {numOfImages.map((index) => (
                    <ExploreProperties key={index} />
                ))}
            </div>

        </div>
    )
}

export default ExploreMain;
