import React from 'react'
import USListing from '../USListing/USListing';
import "./MainListingUS.css";

function MainListingUs() {
   const listings = [1, 2, 3, 4, 5, 6];
  return (
    <div className='maindivUSList'>
            <div className='contentUSList'>
                <div className='sliderUSList'>
                    {listings.map((index) => (
                        <USListing key={index} />
                    ))}
                </div>
            </div>
            <div className='ButtonConatinerUSList'>
                <button>Load More</button>
            </div>
        </div>
  )
}

export default MainListingUs;
