import React from 'react';
import "./Navbar.css";
//import { CiSearch } from "react-icons/ci";
import { MdLocationSearching } from "react-icons/md";
import { MdOutlineKeyboardVoice } from "react-icons/md";

function Navbar() {
    return (
        <div className='center'>
            <div className='mainDiv'>
                <div className='Navbar2'>
                    <div className='filter'>
                        <input type="text" placeholder='All cities' />
                    </div>
                    <div className='searchbar'>
                        <input type="text" placeholder='Enter an address, town, street, zip and property id ' />
                        <div className='icons'>
                            <MdLocationSearching className='icon'/>
                            <MdOutlineKeyboardVoice className='icon' />
                        </div>
                    </div>
                    <div className='NavSearchBtnContainer'>
                        <button>Search</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar;
