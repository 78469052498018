import React, { useState } from 'react';
import "./Leads.css";
import CustomDialog from '../CustomDialog/CustomDialog';

function Leads() {
  const [showDialog, setShowDialog] = useState(false);

  const toggleDialog = () => {
    setShowDialog(prevState => !prevState);
  };

  return (
    <section className='LeadsSectionMainDiv'>
      <div className='LeadsSectionInnerDiv'>
        <div className='LeadsTextbuttonHeader'>
          <p>Leads</p>
          <button onClick={toggleDialog}>Add New Lead</button>
        </div>
        <CustomDialog show={showDialog} onClose={toggleDialog} />
      </div>
    </section>
  );
}

export default Leads;

