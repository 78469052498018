import React, { useState } from 'react'
import "./CAListing.css";
import BGIMG from "../../../Assets/BGIMG.jpg";
import USIMG from "../../../Assets/USIMG.jpg";
import { MdOutlineBed } from "react-icons/md";
import { LiaShowerSolid } from "react-icons/lia";
import { MdSquareFoot } from "react-icons/md";
import { GoPerson } from "react-icons/go";
import { PiLinkSimple } from "react-icons/pi";
import { FaCamera } from "react-icons/fa";
import { ImEnlarge2 } from "react-icons/im";
import { MdCompare } from "react-icons/md";
import { MdFavorite } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

function CAListing() {
    const [currentImage, setCurrentImage] = useState(0);
    const images = [BGIMG, USIMG,];

    const nextImage = () => {
        setCurrentImage((currentImage + 1) % images.length);
    };

    const prevImage = () => {
        setCurrentImage((currentImage - 1 + images.length) % images.length);
    };

    return (
        <div className='CAListMainDiv'>
            <div className='CAListRow'>
                <div className='CAListImageContainer'>
                    <div className='CAListImage'>
                        <IoIosArrowBack className="ListCAArrowBack" onClick={prevImage} />
                        <img src={images[currentImage]} alt="Apartment" />
                        <IoIosArrowForward className='ListCAArrowForward' onClick={nextImage} />
                    </div>
                    <div className='CAListImageItems'>
                        <div className='CAListIcons'>
                            <FaCamera />
                            <ImEnlarge2 />
                            <MdCompare />
                            <MdFavorite />
                        </div>
                    </div>
                </div>
            </div>
            <div className='CAListCol' >
                <div className="CAListTags">
                    <div className="CAListSaleTag">
                        <p>For Selling the property  </p>
                    </div>
                    <div className="CAListRentTag">
                        <p>For Rent</p>
                    </div>
                </div>
                <div className='CAListLocation'>
                    <p style={{ color: "black" }}>123456 AVENUE Canada , Colombia, British, XYZ</p>
                    <p style={{ margin: 0 }}>14555, 78 AVENUE</p>
                </div>
                <div className='CAListFeatureContainer'>
                    <div className='CAListFeatures'>
                        <div className='CAListBedrooms'>
                            <MdOutlineBed className='OutlineBed' />
                            <p>5</p>
                        </div>
                        <div className='CAListShower'>
                            <LiaShowerSolid className='ShowerSolid' />
                            <p>5</p>
                        </div>
                        <div className='CAListSquarefoot'>
                            <MdSquareFoot className='SquareFootCA' />
                            <p>3762 Sqft</p>
                        </div>
                    </div>
                </div>
                <div className='CAListPropertyHeading'>
                    <p style={{ margin: 0 }}>RESIDENTIAL</p>
                </div>

                <div className='CAListDetails'>
                    <div className='CAListAgent'>
                        <GoPerson className='PersonIcon' />
                        <p style={{ color: "gray" }}>Tom Miller</p>
                    </div>
                    <div className='CAListPosted'>
                        <PiLinkSimple className='LinkSimple' />
                        <p style={{ color: "gray" }}>1 week ago</p>
                    </div>
                </div>
            </div>
            <div className='CAListPriceNBtn'>
                <div className='CAListPriceText'>
                    <p style={{ margin: 0 }}>$1,000</p>
                </div>
                <div className='CAListBtnContainer'>
                    <button type="button">Details</button>
                </div>
            </div>
        </div>
    )
}

export default CAListing;
