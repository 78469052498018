import React from 'react'
import "./RecentPropertyDetails.css";
import RecentHeadingDetails from '../../Components/RecentHeadingDetails/RecentHeadingDetails';
import RecentImageDetails from '../../Components/RecentImageDetails/RecentImageDetails';
import ContactWithAgent from '../../Components/ContactWithAgent/ContactWithAgent';
import RecentOverviewDetails from '../../Components/RecentOverviewDetails/RecentOverviewDetails';
import RecentDescriptionDetails from '../../Components/RecentDescriptionDetails/RecentDescriptionDetails';
import RecentAddressDetails from '../../Components/RecentAddressDetails/RecentAddressDetails';
import RecentDetailsSection from '../../Components/RecentDetailsSection/RecentDetailsSection';
import RecentScheduleTourDetails from '../../Components/RecentScheduleTourDetails/RecentScheduleTourDetails';
import RecentFeaturesDetails from '../../Components/RecentFeaturesDetails/RecentFeaturesDetails';
import MortageCalculator from '../../Components/MortageCalculator/MortageCalculator';
import RecentVideoDetails from '../../Components/RecentVideoDetails/RecentVideoDetails';
import RecentContactinfoDetails from '../../Components/RecentContactInformationDetails/RecentContactinfoDetails';
import RecentLeaveReview from '../../Components/RecentLeaveReview/RecentLeaveReview';

function RecentPropertyDetails() {
  return (
    <div className='PropertyDetails'>
      <RecentHeadingDetails/>
      <RecentImageDetails/>
      <ContactWithAgent/>
      <RecentOverviewDetails/>
      <RecentDescriptionDetails/>
      <RecentAddressDetails/>
      <RecentDetailsSection/>
      <RecentScheduleTourDetails/>
      <RecentFeaturesDetails/>
      <MortageCalculator/>
      <RecentVideoDetails/>
      <RecentContactinfoDetails/>
      <RecentLeaveReview/>
    </div>
  )
}

export default RecentPropertyDetails;


