import React from 'react'
import "./RecentHeadingDetails.css";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlineFavoriteBorder } from "react-icons/md";
import { HiOutlineShare } from "react-icons/hi";
import { MdOutlineLocalPrintshop } from "react-icons/md";

function RecentHeadingDetails() {
    return (
        <section className='HeadingDetailSection'>
            <div className='ListHeading'>
                <p className='Ptext'>Beautifully Renovated Home</p>
                <div className='ListingTags'>
                    <p className='ListTag'>New Listing</p>
                    <p className='ListTag'>Featured</p>
                </div>
                <div className='RecentLocation'>
                    <CiLocationOn className='CiLocationOn' />
                    <p>27 Hamilton St</p>
                </div>
            </div>
            <div className='Icon_Price'>
                <div className='FavSharePrintIcons'>
                    <MdOutlineFavoriteBorder className='MdOutlineFavoriteBorder' />
                    <HiOutlineShare className='HiOutlineShare' />
                    <MdOutlineLocalPrintshop className='MdOutlineLocalPrintshop' />
                </div>
                <div className='PriceText'>
                    <p>$ 1,000</p>
                </div>
            </div>
        </section>
    )
}

export default RecentHeadingDetails;

