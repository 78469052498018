import React from 'react'
import "./ListingNavbar.css";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

function ListingNavbar() {
    return (
        
            <div className='centerNav'>
                <div className='PropertyTypeNav'>
                    <div className='all'>
                        <p>All</p>
                    </div>
                    <div className='rent'>
                        <p> For Rent</p>
                    </div>
                    <div className='sale'>
                        <p>For Sale</p>
                    </div>
                </div>
                <div className='Sorting'>
                        <p>Sort By: </p>
                        <p style={{color: "gray"}}>Default Order</p>
                        <MdOutlineKeyboardArrowDown />
                        <ul>
                            <li style = {{borderBottom: "1px solid #DEE5ED"}}>Default Order</li>
                            <li style = {{borderBottom: "1px solid #DEE5ED"}}>Price - Low to High</li>
                            <li style = {{borderBottom: "1px solid #DEE5ED"}}>Price - High to Low</li>
                            <li style = {{borderBottom: "1px solid #DEE5ED"}}>Featured Listings First</li>
                            <li style = {{borderBottom: "1px solid #DEE5ED"}}>Date - Old to New</li>
                            <li style = {{borderBottom: "1px solid #DEE5ED"}}>Date - New to Old</li>
                        </ul>
                </div>
            </div>
        
    )
}

export default ListingNavbar;
