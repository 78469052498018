import React from 'react'
import LandingPage from '../LandingPage/LandingPage'
//import MapSection from '../../Components/GoogleMap/GeoChart'
import MainImageSlider from '../../Components/Carousel/MainImageSlider/MainImageSlider'
import MainSliderFlorida from "../../Components/Carousel/MainImageFlorida/MainSliderFLorida"
import ExploreMain from '../../Components/ExploreMain/ExploreMain';
import Cards from "../../Components/Cards/Cards"
import Footer from '../../Components/Footer/Footer'
import ScrollButton from '../../Components/TopButton/ScrollButton';

function Home() {
  return (
    <div>
      <LandingPage/>
      <MainImageSlider/>
      <MainSliderFlorida/>
      <ExploreMain/>
      <Cards/>
      <ScrollButton/>
      <Footer/>
    </div>
  )
}

export default Home;

