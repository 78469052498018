import React, { useState } from 'react';
import "./RecentContactInfoDetails.css";
import alex from "../../Assets/alex.jpg";
import { MdOutlinePerson } from "react-icons/md";
import { IoIosPhonePortrait } from "react-icons/io";
import { MdOutlinePhone } from "react-icons/md";
import { SlSocialFacebook, SlSocialInstagram, SlSocialLinkedin, SlSocialYoutube } from "react-icons/sl";
import { RiTwitterXFill } from "react-icons/ri";
import { MdArrowDropDown } from "react-icons/md";

function RecentContactinfoDetails() {
    const [showDropdown, setShowDropdown] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const handleInputClick = () => {
        setIsClicked(true);
    };

    const handleClickDropDown = () => {
        setShowDropdown(!showDropdown);
    };



    return (
        <section className='MainDivContactInfoDetails'>
            <div className='RecentContactInfoInnerDiv'>
                <div className='RecentContactInfoDetailsTextDiv'>
                    <p className='RecentContactInfoDetailsHeadingText'>Contact Information</p>
                    <p className='RecentContactInfoDetailsViewListingText'> view listings</p>
                </div>
                <div className='RecentAgentContactInfoDetails'>
                    <div className='AgentContactInfoImageContainer'>
                        <img src={alex} alt="Agent" />
                    </div>
                    <div className='AgentSocialDetailsInfo'>
                        <div className='AgentIconName'>
                            <MdOutlinePerson />
                            <p className='TextFont' >Alex Bhatti</p>
                        </div>
                        <div className='AgentPhoneDetails'>
                            <div className='AgentMdOutlinePhone'>
                                <MdOutlinePhone />
                                <p className='TextFont' >(949) 946-6752949</p>
                            </div>
                            <div className='AgentCallIcon'>
                                <IoIosPhonePortrait />
                                <p className='TextFont' >949-887-4377</p>
                            </div>
                        </div>
                        <div className='AgentSocialIconsDetails'>
                            <div className='SlSocialFacebook' >
                                <SlSocialFacebook />
                            </div>
                            <div className='SlSocialInstagram'>
                                <SlSocialInstagram />
                            </div>
                            <div className='SlSocialLinkedin'>
                                <SlSocialLinkedin />
                            </div>
                            <div className='SlSocialYoutube'>
                                <SlSocialYoutube />
                            </div>
                            <div className='RiTwitterXFill' >
                                <RiTwitterXFill />
                            </div>
                        </div>
                    </div>
                </div>
                <p className='EnquirePropertyText'>Enquire About This Property</p>
                <div>
                    <div className='FirstRow'>
                        <div className='AgentDetailsInputFields'>
                            <p style={{ fontWeight: "bold" }}>Name</p>
                            <input type="text" placeholder='Name' className={isClicked ? 'input-clicked' : 'input-normal'}
                                onClick={handleInputClick} />
                        </div>
                        <div className='AgentDetailsInputFields'>
                            <p style={{ fontWeight: "bold" }}>Phone</p>
                            <input type="Number" placeholder='Phone' className={isClicked ? 'input-clicked' : 'input-normal'}
                                onClick={handleInputClick} />
                        </div>
                    </div>
                    <div className='SecondRow'>
                        <div className='AgentDetailsInputFields'>
                            <p style={{ fontWeight: "bold" }}>Email</p>
                            <input type="Email" placeholder='Email' className={isClicked ? 'input-clicked' : 'input-normal'}
                                onClick={handleInputClick} />
                        </div>
                        <div className='AgentDetailsDropDownInputField'>
                            <p style={{ fontWeight: "bold" }}>I'm a</p>
                            <input type="text" placeholder='Select' className={isClicked ? 'input-clicked' : 'input-normal'}
                                onClick={handleInputClick} />
                            <MdArrowDropDown className='MdArrowDropDown' onClick={handleClickDropDown} />
                            {showDropdown && (
                                <ul>
                                    <li>I'm a buyer</li>
                                    <li>I'm a tenant</li>
                                    <li>I'm an agent</li>
                                    <li>other</li>
                                </ul>
                            )}
                        </div>
                    </div>
                    <div className='TextAreaContainer'>
                        <p style={{ fontWeight: "bold", fontSize: "14px" }}>Message</p>
                        <textarea placeholder='Enter Your Message' cols="102" rows="5" className={isClicked ? 'input-clicked' : 'input-normal'}
                            onClick={handleInputClick}></textarea>
                    </div>
                    <div className='SubmittingText'>
                        <input type="checkbox" />
                        <p>By submitting this form I agree to <span style={{ color: "#00AEFF" }}> Terms of Use </span> </p>
                    </div>
                    <div className='RequestInformationAgentDetailsBtn'>
                        <button>Request Information</button>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default RecentContactinfoDetails;
