import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';

function BarChart() {
    const chartRef = useRef(null);

    useEffect(() => {
        // Initialize echarts instance
        const chart = echarts.init(chartRef.current);

        // Specify chart configuration and set the option
        const option = {
            legend: {},
            tooltip: {},
            dataset: {
                dimensions: ['product', 'Visits', 'Unique', ''],
                source: [
                    { product: 'Last 24 Hours', 'Visits': 43.3, 'Unique': 85.8 },
                    { product: 'Last 7 Days', 'Visits': 83.1, 'Unique': 73.4 },
                    { product: 'Last 15 Days', 'Visits': 86.4, 'Unique': 65.2 },
                    { product: 'Last 30 Days', 'Visits': 72.4, 'Unique': 53.9 }
                ]
            },
            xAxis: { type: 'category' },
            yAxis: {},
            // Declare several bar series, each will be mapped
            // to a column of dataset.source by default.
            series: [
                { type: 'bar', itemStyle: { color: '#121318' } }, // Custom color for the first bar series
                { type: 'bar', itemStyle: { color: '#AE1D23' } }, // Custom color for the second bar series
                { type: 'bar' }
            ]
        };

        // Set chart option
        chart.setOption(option);

        // Cleanup function
        return () => {
            chart.dispose(); // Dispose the chart instance to avoid memory leaks
        };
    }, []);

    return (
        <div
            className='barChartDB'
            ref={chartRef}
        />
    );
}

export default BarChart;
