import React, { useState } from 'react';
import "./RecentLeaveReview.css";
import { MdArrowDropDown } from "react-icons/md";

function RecentLeaveReview() {
    const [showDropdown, setShowDropdown] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const handleInputClick = () => {
        setIsClicked(true);
    };

    const handleClickDropDown = () => {
        setShowDropdown(!showDropdown);
    };



    return (
        <section className='MainDivLeaveRevieDetails'>
            <div className='RecentLeaveReviewInnerDiv'>
                <p className='RecentLeaveReviewDetailsHeadingText'>Leave a Review</p>
                    <div className='LeaveReviewSecondRow'>
                        <div className='LeaveReviewInputFields'>
                            <p style={{ fontWeight: "bold" }}>Title</p>
                            <input type="text" placeholder='Enter a Title' className={isClicked ? 'input-clicked' : 'input-normal'}
                                onClick={handleInputClick} />
                        </div>
                        <div className='LeaveReviewDropDownInputField'>
                            <p style={{ fontWeight: "bold" }}>Rating</p>
                            <input type="text" placeholder='Select' className={isClicked ? 'input-clicked' : 'input-normal'}
                                onClick={handleInputClick} />
                            <MdArrowDropDown className='MdArrowDropDown' onClick={handleClickDropDown} />
                            {showDropdown && (
                                <ul>
                                    <li>1 Star - Poor</li>
                                    <li>2 Star - Fair</li>
                                    <li>3 Star - Average</li>
                                    <li>4 Star - Good</li>
                                    <li>5 Star - Excellent</li>
                                </ul>
                            )}
                        </div>
                    </div>
                    <div className='LeaveReviewTextAreaContainer'>
                        <p style={{ fontWeight: "bold" }}>Message</p>
                        <textarea placeholder='Enter Your Message' cols="102" rows="5" className={isClicked ? 'input-clicked' : 'input-normal'}
                            onClick={handleInputClick}></textarea>
                    </div>
                    <div className='LeaveReviewSubmitText'>
                        <input type="checkbox" />
                        <p>By submitting this form I agree to <span style={{ color: "#00AEFF" }}> Terms of Use </span> </p>
                    </div>
                    <div className='LeaveReviewRecentDetailsBtn'>
                        <button>Submit Review</button>
                    </div>
                
            </div>
        </section>
    );
}

export default RecentLeaveReview;
