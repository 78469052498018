import React, { useRef, useState } from 'react';
import "./CreateListingsPage2.css";
import { MdOutlineFileUpload } from "react-icons/md";
import { IoMdImages } from "react-icons/io";
import { GrFormNextLink } from "react-icons/gr";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Link } from 'react-router-dom';

function CreatelistingsPage2() {
    const fileInputRef = useRef(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isClicked, setIsClicked] = useState(false);

    const handleInputClick = () => {
        setIsClicked(true);
    };

    const handleFileUpload = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const clearSelectedImage = () => {
        setSelectedImage(null);
        // Clear file input value to allow selecting the same file again
        fileInputRef.current.value = "";
    };

    return (
        <section className='CreateListingsPage2MainDiv'>
            <div className='CreateListingsPage2SectionInnerDiv' >
                <div className='CreateListingsPage2HeaderTextBtn'>
                    <p>Create Listings</p>
                    <button >Save as Draft</button>
                </div>
            </div>

            <div className='CreateListingsPage2UploadImageMainSection'>
                <p style={{ fontSize: '14px', fontWeight: "normal" }}>
                    Drag and drop the images to customize the image gallery order.
                </p>
                <div className='CreateListingsPage2UploadImageInnerDiv'>
                    <div className='UploadImageMainDivPage2'>
                        <div className='UploadImageIconTextInnerDiv'>
                            <div className='MDImagesIconDiv'>
                                <IoMdImages style={{ fontSize: "30px", color: "#DEE5ED" }} />
                            </div>

                            <p style={{ fontWeight: "bold", fontSize: '14px', marginBottom: 0 }}>
                                Drag and drop the gallery images here
                            </p>
                            <p style={{ fontWeight: "normal", fontSize: "12px", marginTop: 0 }}>
                                (Minimum size 1440x900)
                            </p>
                            <div className='CreateListingsPage2SelectUploadBtnContainer'>
                                <button onClick={handleFileUpload}>
                                    <MdOutlineFileUpload style={{ fontSize: "20px" }} />
                                    Select and upload
                                </button>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: "none" }}
                                    onChange={handleFileChange}
                                    accept="image/*" // accept only image files
                                    onClick={handleInputClick}
                                    className={isClicked ? 'input-clicked' : 'input-normal'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {selectedImage && (
                    <div style={{ position: 'relative' }}>
                        <p>Selected Image:</p>
                        <img src={selectedImage} alt="Selected" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                        <AiOutlineCloseCircle onClick={clearSelectedImage} style={{ position: 'absolute', top: '5px', right: '5px', fontSize: '20px', cursor: 'pointer' }} />
                    </div>
                )}
                <p style={{ fontSize: "14px", fontWeight: "normal" }}>Click on the star icon to select the cover image.</p>
            </div>


            <div className='CreateListingsPage2VideoUrlMainDiv'>
                <p style={{ fontSize: '14px', fontWeight: "bold" }}>Video Url</p>
                <div className='CreateListingsPage2InputFields'>
                    <input type="text" placeholder='Youtube, Vimeo, SWF file and MOV file are supported' onClick={handleInputClick}
                        className={isClicked ? 'input-clicked' : 'input-normal'} />
                </div>
                <p style={{ fontSize: '10px', fontWeight: "normal", margin: "5px" }}> For example: https://www.youtube.com/watch?v=49d3Gn41IaA</p>
            </div>

            <div className='CreateListingsPage2Footer'>
                <div className='CreateListingsPage2BtnContainerFooter'>
                    <div className='CreateListingsPage2BackBtnIconContainer'>
                        <Link to="/CreateListings" className='BackBtnLink'> <button>Back</button> </Link>
                    </div>
                    <div className='CreateListingsPage2NextBtnIconContainer'>
                        <Link to="/CreateListings-Page3" className='NextBtnLink'>   <button>Next
                            <GrFormNextLink style={{ fontSize: '30px', marginLeft: "10px" }} />
                        </button>
                        </Link>
                    </div>
                </div>
            </div>


        </section>
    );
}

export default CreatelistingsPage2;
