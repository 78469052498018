import React, { useState } from 'react';
import './RecentImageDetails.css';
import USIMG from '../../Assets/USIMG.jpg';
import BGIMG from "../../Assets/BGIMG.jpg";
import BGIMGFlorida from "../../Assets/BGIMGFlorida.jpg";
import Image1 from "../../Assets/Image1.jpg";
import Image2 from "../../Assets/Image2.jpg";
import Image3 from "../../Assets/Image3.jpg";
import Image4 from "../../Assets/Image4.jpg";
import Image5 from "../../Assets/Image5.jpg";
import Image6 from "../../Assets/Image6.jpg";
import Image7 from "../../Assets/Image7.jpg";
import image8 from "../../Assets/image8.jpg";
import Image9 from "../../Assets/Image9.jpg";
import { IoIosArrowBack } from 'react-icons/io';
import { IoIosArrowForward } from 'react-icons/io';

function RecentImageDetails() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [BGIMG, USIMG, BGIMGFlorida, Image1, Image6, Image3, Image9, Image5, Image2, Image7, image8, Image4, Image2, Image6, Image4, Image7];
  let visibleImages = images.slice(0, 8);
  if (currentImageIndex + 8 <= images.length) {
    visibleImages = images.slice(currentImageIndex, currentImageIndex + 8);
  } else {
    visibleImages = images.slice(currentImageIndex).concat(images.slice(0, currentImageIndex + 8 - images.length));
  }

  const goToPreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };
  const handleImageClick = (imageUrl) => {
    setCurrentImageIndex(images.indexOf(imageUrl));
  };

  return (
    <section className='ImageDivRecent'>
      <div className='ImageContainerRecent'>
        <IoIosArrowBack className='ArrowIcon' onClick={goToPreviousImage} />
        <div className="ImageWrapper" style={{ transform: `translateX(-${currentImageIndex * 100}%)` }}>
          {images.map((_, index) => (
            <img
              key={index}
              src={images[currentImageIndex]}
              alt={`Apartment ${index + 1}`}
            />
          ))}
        </div>
        <IoIosArrowForward className='ArrowIcon' onClick={goToNextImage} />
      </div>
      <div className='ImageSlide'>
        <ul className='ImageUL'>
          {visibleImages.map((image, index) => (
            <li className='ImageList' key={index} onClick={() => handleImageClick(image)}>
              <img
                src={image} alt={`Apartment ${index + 1}`} />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default RecentImageDetails;
