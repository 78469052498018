import React, { useState } from 'react'
import "./ImageSlider.css";
import BGIMG from "../../../Assets/BGIMG.jpg";
import BGIMGFlorida from "../../../Assets/BGIMGFlorida.jpg";
import { MdOutlineBed } from "react-icons/md";
import { LiaShowerSolid } from "react-icons/lia";
import { MdSquareFoot } from "react-icons/md";
import { GoPerson } from "react-icons/go";
import { PiLinkSimple } from "react-icons/pi";
import { FaCamera } from "react-icons/fa";
import { ImEnlarge2 } from "react-icons/im";
import { MdCompare } from "react-icons/md";
import { MdFavorite } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from 'react-router-dom';


function ImageSlider() {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [BGIMG, BGIMGFlorida,];

  const nextImage = () => {
    setCurrentImage((currentImage + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImage((currentImage - 1 + images.length) % images.length);
  };

  return (
    <div className='Container'>
      <Link to="/Properties-US/Property-Details"> <div className='image'>
        <div className='imagez'>
          <IoIosArrowBack className="lessIcon" onClick={prevImage} />
          <img src={images[currentImage]} alt="Apartment" />
          <IoIosArrowForward className='greatIcon' onClick={nextImage} />
        </div>
        <div className="Tags">
          <div className="SaleTag">
            <p>For Selling the property  </p>
          </div>
          <div className="RentTag">
            <p>For Rent</p>
          </div>
        </div>
        <div className='imageItems'>
          <div className='text'>
            <p>$1,000</p>
          </div>
          <div className='Icons'>
            <FaCamera title='camera' />
            <ImEnlarge2 />
            <MdCompare />
            <MdFavorite />
          </div>
        </div>
      </div>
      </Link>
      <div className='location'>
        <p style={{ color: "black" }}>123456 AVENUE Canada , Colombia, British, XYZ</p>
        <p style={{ margin: 0 }}>14555, 78 AVENUE</p>
      </div>
      <div className='featureConatiner'>
        <div className='features'>
          <div className='bedrooms'>
            <MdOutlineBed />
            <p>5</p>
          </div>
          <div className='shower'>
            <LiaShowerSolid />
            <p>5</p>
          </div>
          <div className='squarefoot'>
            <MdSquareFoot />
            <p>3762 Sqft</p>
          </div>
        </div>
        <div className='btnContainer'>
          <button type="button">Details</button>
        </div>
      </div>
      <div className='propertyHeading'>
        <p style={{ margin: 0 }}>RESIDENTIAL</p>
      </div>

      <div className='details'>
        <div className='agent'>
          <GoPerson />
          <p>Tom Miller</p>
        </div>
        <div className='posted'>
          <PiLinkSimple />
          <p>1 week ago</p>
        </div>
      </div>
    </div>
  )
}

export default ImageSlider;
