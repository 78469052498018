import React from 'react'
import "./AgentProfileContainer.css";
import AutumnMakin from "../../../Assets/AutumnMakin.jpg";

function AgentProfileContainer() {
    return (
        <section className='AgentProfileMainDiv'>
            <div className='AgentProfileInnerDiv'>
                <div className='AgentProfileImageNameDetails'>
                    <div className='AgentProfileMainImageContainer'>
                        <img src={AutumnMakin} alt="Agent" />
                    </div>
                    <div className='AgentProfileNameDetails'>
                        <p className='AgentNamePtag'>Autumn Makin</p>
                        <p className='AgentReviewsPtag'>See all reviews</p>
                        <p className='AgentListingLocation'> at
                            <span style={{ color: "#17bdff" }}> Lakefield Realty Group </span>
                        </p>
                        <p className='AgentLicensePtag'>
                            <span style={{ fontWeight: "bold" }}>Agent License: </span>  SL3026402
                        </p>
                        <p className='AgentLocationPtag'>
                            <span style={{ fontWeight: "bold" }}>Service Areas: </span>
                            Central Florida, Lake County, Orange County, Clermont, Minneola, Winter Garden, Orlando, Windermere, Leesburg, Mount Dora, Tavares</p>
                        <p className='AgentSpecialtiesPtag'>
                            <span style={{ fontWeight: "bold" }}> Specialties: </span>  Listing Agent, Buyers Agent, Historical Homes, Staging</p>
                        <div className='AgentProfileButtonContainer'>
                            <button className='AgentProfileSendEmailBtn' >Send Email</button>
                            <button className='AgentProfileCallBtn'>Call</button>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default AgentProfileContainer;
