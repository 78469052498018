import React from 'react'
import "./ExploreProperties.css";
import BGIMGFlorida from "../../Assets/BGIMGFlorida.jpg"
import { FaPlay } from "react-icons/fa";

function ExploreProperties() {
    return (
        <div className='explore'>
            <div className='ImageContainer'>
                <div className='imagecontain'>
                    <img src={BGIMGFlorida} alt="Apartments" />
                </div>
                <div className='PropertyType'>
                    <p >4 Properties</p>
                    <p style={{ lineHeight: "0.1" }}>Colorado Springs </p>
                </div>
                <div className='ImageDetails'>
                    <p>More Details</p>
                    <FaPlay />
                </div>
            </div>
        </div>
    )
}

export default ExploreProperties;
