import React from 'react'

function Favorite() {
  return (
    <div style={{margin: "200px"}}>
      <h1 style = {{color: "red"}}>Favorite</h1>
    </div>
  )
}

export default Favorite
