import React, { useState } from 'react';
import AgentListingsDetails from "../AgentListingsDetails/AgentListingsDetails";
import AgentReviews from "../AgentReviews/AgentReviews";
import "./MainAgentListingsDetails.css";

function MainAgentListingsDetails() {
    const [showListings, setShowListings] = useState(true);

    const handleListingsClick = () => {
        setShowListings(true); // Show Listings
    }

    const handleReviewsClick = () => {
        setShowListings(false); // Show Reviews
    }

    const listings = [1, 2, 3, 4, 5];

    return (
        <div className='maindivAgentListingsDetails'>
            <div className='contentAgentListingsDetails'>
                <div className='AgentNameListingReviewsBtnContainer'>
                    <button className={showListings ? 'AgentNameContactListingBtn active' : 'AgentNameContactListingBtn'} onClick={handleListingsClick}>Listings (5)</button>
                    <button className={!showListings ? 'AgentNameContactReviewBtn active' : 'AgentNameContactReviewBtn'} onClick={handleReviewsClick}>Reviews (0)</button>
                </div>
                <div className='sliderAgentsListingsDetails'>
                    {/* Conditionally render Listings or Reviews based on state */}
                    {showListings ?
                        listings.map((index) => (
                            <AgentListingsDetails key={index} />
                        ))
                        :
                        <AgentReviews />
                    }
                </div>
            </div>
        </div>
    )
}

export default MainAgentListingsDetails;
