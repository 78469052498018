import React from 'react'
import "./AgentReviews.css";



function AgentReviews() {

    return (
        <section className='AgentReviewsMainDiv'>
            <div className='AgentReviewsMainInnerDiv'>
                <p className='LeaveAReviewAgentText'> Leave a Review</p>
                <div className='AgentReviewInputFieldsContainer'>
                    <div className='AgentEmailInputField'>
                        <p>Email</p>
                        <input type="email" placeholder='you@gmail.com' />
                    </div>
                    <div className='AgentTitleRatingInputFields'>
                        <div className='AgentTitleInputField'>
                            <p>Title</p>
                            <input type="text" placeholder='Enter a Title' />
                        </div>
                        <div className='AgentReviewInputField'>
                            <p>Rating</p>
                            <input type="text" placeholder='select' />
                        </div>

                    </div>
                    <div className='AgentReviewTextArea'>
                        <p>Review</p>
                        <textarea rows="10" cols= "100"  placeholder= "Write a Review"></textarea>
                    </div>
                </div>
                <div className='AgentReviewButtonContainer'>
                    <button>Submit Review</button>
                </div>
            </div>
        </section>
    )
}

export default AgentReviews;
