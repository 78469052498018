import React, { useState, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai'; // Importing close icon
import "./CustomDialog.css";

function CustomDialog({ onClose, show }) {
  const [isVisible, setIsVisible] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleInputClick = () => {
    setIsClicked(true);
};

  useEffect(() => {
    setIsVisible(show);
  }, [show]);

  const handleClose = () => {
    setIsVisible(false);
    onClose();
  };

  return (
    <section className={`CustomDialogMainDiv ${isVisible ? 'show' : 'hide'}`}>
      <div className='CustomDialogInnerDiv'>
        <div className="CloseIconContainer" onClick={handleClose}>
          <AiOutlineClose />
        </div>
        <p style={{ margin: "5px", fontSize: "18px" }}>Add New Lead</p>

        <div className='MainInformationDivLeads'>
          <p style={{ fontSize: "16px" }}>Information</p>
          <div className='LeadsInputfieldsRow1'>
            <p>First Name</p>
            <input type="text" placeholder='First Name' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
          </div>
          <div className='LeadsInputfieldsRow2'>
            <p>Last Name</p>
            <input type="text" placeholder='First Name' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'}  />
          </div>
          <div className='LeadsInputfieldsRow3'>
            <p>Type</p>
            <input type="text" placeholder='Enter user type (buyer, agent etc)' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
          </div>
        </div>

        <div className='MainContactsDivLeads'>
          <p style={{ fontSize: "16px" }}>Contacts</p>
          <div className='LeadsInputfieldsRow4'>
            <p>Email</p>
            <input type="email" placeholder='Email' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'}  />
          </div>
          <div className='LeadsInputfieldsRow5'>
            <p>Mobile</p>
            <input type="text" placeholder='Mobile Number' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'}  />
          </div>
          <div className='LeadsInputfieldsRow6'>
            <p>Home</p>
            <input type="text" placeholder='Home Phone Number' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'}  />
          </div>
          <div className='LeadsInputfieldsRow7'>
            <p>Work</p>
            <input type="text" placeholder='Work Phone Number' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'}  />
          </div>
        </div>

        <div className='MainAdressDivLeads'>
          <p style={{ fontSize: "16px" }}>Address</p>
          <div className='LeadsInputfieldsRow8'>
            <p>Address</p>
            <input type="text" placeholder='Address' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'}  />
          </div>
          <div className='LeadsInputfieldsRow9'>
            <div>
              <p>Country</p>
              <input type="text" placeholder='Country' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'}  />
            </div>
            <div>
              <p>City</p>
              <input type="text" placeholder='City' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'}  />
            </div>
          </div>
          <div className='LeadsInputfieldsRow10'>
            <div>
              <p>County / State</p>
              <input type="text" placeholder='County / State' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'}  />
            </div>
            <div>
              <p>Postal code / Zip</p>
              <input type="text" placeholder='Postal code / Zip' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'}  />
            </div>
          </div>
        </div>

        <div className='MainSourceDivLeads'>
          <p style={{ fontSize: "16px" }}>Source</p>
          <div className='LeadsInputfieldsRow11'>
            <p>Source</p>
            <input type="text" placeholder='Address' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'}  />
          </div>
        </div>

        <div className='MainSocialDivLeads'>
          <p style={{ fontSize: "16px" }}>Social</p>
          <div className='LeadsInputfieldsRow1'>
            <p>Facebook</p>
            <input type="text" placeholder='Facebook' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'}  />
          </div>
          <div className='LeadsInputfieldsRow2'>
            <p>X</p>
            <input type="text" placeholder='X' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
          </div>
          <div className='LeadsInputfieldsRow3'>
            <p>LinkedIn</p>
            <input type="text" placeholder='Enter LinkedIn' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'}  />
          </div>
        </div>

        <div className='MainPrivateNoteDivLeads'>
          <p style={{ fontSize: "16px" }}>Private Note</p>
          <div className='PrivateNoteTextAreaContainer'>
            <textarea cols="30" rows="10" onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} ></textarea>
          </div>
        </div>

        <div className='CustomModalSaveBtnContainer'>
          <button>Save</button>
        </div>

      </div>
    </section>
  );
}

export default CustomDialog;
