import React from 'react'
import "./RecentAddressDetails.css";

function RecentAddressDetails() {
    return (
        <section className='MainDivAddressDetails'>
            <div className='RecentAddressDetailsInnerDiv'>
                <div className='RecentAddressDetails'>
                    <p>Address</p>
                    <p className='GoogleMap'>Open on Google Maps</p>
                </div>
                <div className='AdressCompleteDetails'>
                    <div className='AddressCol1'>
                        <div className='AddressDetails'>
                            <h4>Address</h4>
                            <p>424 SE Wildflower Drive</p>
                        </div>
                        <div className='CityDetails'>
                            <h4>City</h4>
                            <p>Madras</p>
                        </div>
                        <div className='StateCounty'>
                            <h4>State/County</h4>
                            <p>Oregon</p>
                        </div>
                    </div>
                    <div className='AddressCol2'>
                        <div className='ZipPostalCode'>
                            <h4>Zip/Postal Code</h4>
                            <p>97741</p>
                        </div>
                        <div className='AreaDetails'>
                            <h4>Area</h4>
                            <p>Madras</p>
                        </div>
                        <div className='CountryDetails'>
                            <h4>Country</h4>
                            <p>United States
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RecentAddressDetails;
