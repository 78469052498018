import React, { useState } from 'react'
import "./ContactWithAgent.css";
import alex from "../../Assets/alex.jpg"
import { GoPerson } from "react-icons/go";
import { useEffect } from "react";
import { MdArrowDropDown } from "react-icons/md";
import { Link } from 'react-router-dom';

function ContactWithAgent() {
    const [isFixed, setIsFixed] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);

    const handleClickDropDown = () => {
        setShowDropdown(!showDropdown);
    };
    const handleInputClick = () => {
        setIsClicked(true);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 200) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className={`AgentCardMainDiv ${isFixed ? 'fixed' : ''}`}>
            <div className='ImageField'>
                <div className='ImageDiv'>
                    <img src={alex} alt="AgentProfile" />
                </div>
                <div className='AgentName'>
                    <div className='IconAgentName'>
                        <GoPerson className='GoPerson' />
                        <p style={{ margin: 0 }}>Alex Miller</p>
                    </div>
                    <div className='ContactWithAgentViewListingText' >
                        <Link className='ViewListingLink' to='/AgentListing'>
                            <p style={{ margin: 0, color: '#00B6FF' }}>view listing</p>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='InputFields'>
                <input type="text" placeholder='Name' className={isClicked ? 'input-clicked' : 'input-normal'}
                    onClick={handleInputClick} />
                <input type="Number" placeholder='Phone' className={isClicked ? 'input-clicked' : 'input-normal'}
                    onClick={handleInputClick} />
                <input type="Email" placeholder='Email' className={isClicked ? 'input-clicked' : 'input-normal'}
                    onClick={handleInputClick} />
                <textarea placeholder='Message' rows="5" className={isClicked ? 'input-clicked' : 'input-normal'}
                    onClick={handleInputClick}></textarea>
                <div className='ContactWithAgentDropDownInputField'>
                    <input type="text" placeholder='Select' className={isClicked ? 'input-clicked' : 'input-normal'}
                        onClick={handleInputClick} />
                    <MdArrowDropDown className='MDArrowDropDown' onClick={handleClickDropDown} />
                    {showDropdown && (
                        <ul>
                            <li>I'm a buyer</li>
                            <li>I'm a tenant</li>
                            <li>I'm an agent</li>
                            <li>other</li>
                        </ul>
                    )}
                </div>

                <div className='CheckBoxText'>
                    <input type="checkbox" />
                    <p className='SubmittingFormText'>By submitting this form I agree to
                        <span className='CheckBoxTextSpan'>Terms of Use</span>
                    </p>
                </div>
            </div>
            <div className='MessageCallButtons'>
                <button className='MsgBtn'>
                    Send Message
                </button>
                <button className='CallBtn'>
                    Call
                </button>
            </div>
        </div>
    )
}

export default ContactWithAgent;
