import React from 'react';
import "./Dashboard.css";
import alex from "../Assets/alex.jpg"
import BarChart from "../Dashboard/BarChart/BarChart";
import dashboard_contact from "../Assets/dashboard_contact.png";
import PieChart from "../Dashboard/PieChart/PieChart"
import { IoPersonSharp } from "react-icons/io5";
import { RiArrowDropDownLine } from "react-icons/ri";
import { IoIosArrowForward } from "react-icons/io";
import { IoInformation } from "react-icons/io5";
import { FaCheck } from "react-icons/fa";
import { Link } from 'react-router-dom';


function Dashboard() {

    return (

        <section className='DashboardMainDivSection'>
            <div className='DashboardHeaderMainDiv'>
                <div>
                    <p style={{ fontWeight: "bold", fontSize: '18px' }}>Dashboard</p>
                </div>
                <div className='DashboardHeaderInnerDiv' >
                    <div className='AdminTextDiv'>
                        <p style={{ margin: 0 }}>Hey, <span style={{ fontWeight: 'bold' }}>Tom</span> </p>
                        <p style={{ textAlign: "center", margin: 0 }} >Admin</p>
                    </div>

                    <div className='DashboardAgentImageContainer'>
                        <img src={alex} alt="Agent" />
                    </div>
                </div>

            </div>

            <div className='DashboardMainDivSection2'>
                <div className='DashboardMainDivSection2Row1'>
                    <div className='FirsttowCards'>
                        <div className='ProfileSetupDiv'>
                            <p className='ProfileSetupText' >Profile Setup</p>
                            <p className='ProfileSetupValue' >30%</p>
                        </div>
                        <div className='LeadsDiv'>
                            <p className='LeadsText' >Leads</p>
                            <p className='LeadsTextValue' >235</p>
                        </div>
                    </div>

                    <div className='LasttowCards'>
                        <div className='ActiveListingsDiv'>
                            <p className='ActiveListingsText'>Active Listings</p>
                            <p className='ActiveListingsTextValue' >35</p>
                        </div>
                        <div className='PendingListings'>
                            <p className='PendingListingsText' style={{ fontSize: '15px' }}>Pending Listings</p>
                            <p className='PendingListingsTextValue' style={{ fontSize: "30px", margin: 0 }}>02</p>
                        </div>
                    </div>
                </div>

                <div className='DashboardMainDivSection2Row2'>
                    <div className='InsightsMainDiv'>
                        <div className='InsightsTextBtnContainerDiv'>
                            <p style={{ marginLeft: "20px", fontSize: '15px' }}>Insights</p>
                            <button>This year<RiArrowDropDownLine style={{ fontSize: '20px' }} /></button>
                        </div>

                        <BarChart />
                    </div>
                    <div className='LeadsInquiriesDiv'>
                        <div className='LeadsRangeDiv'>
                            <div className='LeadsTextBtnContainerDiv'>
                                <p style={{ margin: 0, fontSize: '15px' }}>Leads</p>
                                <button>This year<RiArrowDropDownLine style={{ fontSize: '20px' }} /></button>
                            </div>
                            <div className='PieChartDB'>
                                <PieChart />
                            </div>

                        </div>
                        <div className='InquiriesDiv'>
                            <p style={{ margin: 0, fontSize: '15px' }}>Listings</p>
                            <div className='ListingsTitle1' style={{ marginTop: "10px" }}>
                                <p>Birchley Park (Scarborough/Toronto)</p>
                                <div className='ListingsTitleTags1'>
                                    <button>Approved</button>
                                </div>
                            </div>

                            <div className='ListingsTitle2'>
                                <p>1064 CAVENDER CREEK RD, MINNEOLA, FL 34715</p>
                                <div className='ListingsTitleTags2'>
                                    <button>Pending</button>
                                </div>
                            </div>

                            <div className='ListingsTitle3'>
                                <p>12250 WOODGLEN CIR, CLERMONT, FL 34711</p>
                                <div className='ListingsTitleTags3'>
                                    <button>Draft</button>
                                </div>
                            </div>

                            <div className='ListingsTitle4'>
                                <p>CUSTOM POOL HOME W/ SPECTACULAR LAKE VIE</p>
                                <div className='ListingsTitleTags4'>
                                    <button>Expired</button>
                                </div>
                            </div>

                            <div className='ListingsTitle5'>
                                <p>Waterfront Hawkesbury Ontario</p>
                                <div className='ListingsTitleTags5'>
                                    <button>Onhold</button>
                                </div>
                            </div>

                            <div className='ViewAllBtnDiv'>
                                <button>View All</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='LastRowCardsDB'>
                    <div className='DashboardMainDivSection2Row3'>
                        <div style={{
                            display: "flex", flexDirection: "row",
                            alignItems: "center", alignContent: "center", justifyContent: 'space-between'
                        }}>
                            <p style={{ fontSize: '15px' }}>Updates</p>
                            <div className='ViewAllNotificationBtnDiv'>
                                <Link to="/notifications">  <button>View All</button> </Link>
                            </div>
                        </div>


                        <div className='LeadsNotificationDiv'>
                            <div className='NotificationPersonIconDiv'>
                                <IoPersonSharp style={{ color: "white", fontSize: "35px" }} />
                            </div>
                            <div className='LeadsRecievedNotificationDiv'>
                                <div className='LeadsRecievedRow1'>
                                    <p style={{ margin: 0, fontSize: '13px' }}>A new buyer lead recieved </p>
                                    <p style={{ margin: 0, fontSize: '13px', fontWeight: 'bold' }}>John Doe</p>
                                </div>
                                <div className='LeadsRecievedRow2'>
                                    <p style={{ margin: 0, fontSize: '13px' }}>email@email.com</p>
                                    <p style={{ margin: 0, fontSize: '13px', color: '#B8B8B8' }}>Today, 6:01pm</p>
                                </div>
                            </div>
                            <div className='NotificationArrowIconDiv'>
                                <IoIosArrowForward style={{ fontSize: '2rem' }} />
                            </div>
                        </div>
                        <div className='ListingsStatusNotificationDiv'>
                            <div className='NotificationCheckIconDiv'>
                                <FaCheck style={{ color: "white", fontSize: "35px" }} />
                            </div>
                            <div className='ListingsRecievedNotificationDiv'>
                                <div className='ListingsRecievedRow1'>
                                    <p style={{ margin: 0, fontSize: '13px' }}>Your listing has been approved </p>
                                    <p style={{ margin: 0, fontSize: '13px', fontWeight: 'bold' }}>CUSTOM POOL HOME W/ SPECT</p>
                                </div>
                                <div className='ListingsRecievedRow2'>
                                    <p style={{ margin: 0, fontSize: '13px' }}>email@email.com</p>
                                    <p style={{ margin: 0, fontSize: '13px', color: '#B8B8B8' }}>Yesterday, 6:01pm</p>
                                </div>
                            </div>
                            <div className='NotificationArrowIconDiv'>
                                <IoIosArrowForward style={{ fontSize: '2rem' }} />
                            </div>
                        </div>
                        <div className='ListingsExpiredNotificationDiv'>
                            <div className='NotificationInformationIconDiv'>
                                <IoInformation style={{ color: "black", fontSize: "30px" }} />
                            </div>
                            <div className='ListingsExpiredRecievedNotificationDiv'>
                                <div className='ListingsExpiredRecievedRow1'>
                                    <p style={{ margin: 0, fontSize: '13px' }}>Your listing is expiring soon</p>
                                    <p style={{ margin: 0, fontSize: '13px', fontWeight: 'bold' }}>1064 CAVENDER CREEK RD, MIN</p>
                                </div>
                                <div className='ListingsExpiredRecievedRow2'>
                                    <p style={{ margin: 0, fontSize: '13px' }}>email@email.com</p>
                                    <p style={{ margin: 0, fontSize: '13px', color: '#B8B8B8' }}>05/07/2024, 12:10pm</p>
                                </div>
                            </div>
                            <div className='NotificationArrowIconDiv'>
                                <IoIosArrowForward style={{ fontSize: '2rem' }} />
                            </div>
                        </div>


                    </div>

                    <div className='mapAgentLocation'>
                        <div className='IllustrationContactText' >
                            <p>Got a question?</p>
                            <p>Get in touch with us! </p>
                            <p>support@lezooka.com</p>
                        </div>
                        <div className='dbContactImageContainer'>
                            <img src={dashboard_contact} alt="contact" />
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Dashboard;
