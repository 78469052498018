import React from 'react'
import alex from "../../Assets/alex.jpg";
import "./AgentsCard.css";

function AgentsCard() {
  return (
    <div className='Main_Container'>
      <div className='AgentContainer'>
        <img src={alex} alt="Agent" />
        <h6>Alex Camaerei</h6>
        <h5 style = {{marginTop: "-20px"}}>C21 Scheetz</h5>
        <p>As a 9 year veteran of the real estate business, my expertise spans from...</p>
        <h4>View Profile</h4>
      </div>
    </div>
  )
}

export default AgentsCard;
