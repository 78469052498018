//Layout.js
import React from 'react'
import { Outlet } from "react-router-dom";
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import TopButton from "../Components/TopButton/ScrollButton";
//import BreadCrumbs from '../BreadCrumbs/BreadCrumbs';
function Layout() {
    return (
        <div>
            <Header/>
            <Outlet/>
            <TopButton/>
            <Footer/>
        </div>
    )
}

export default Layout;