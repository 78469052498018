import React, { useEffect } from 'react';
import * as echarts from 'echarts';

const TopBrowserPieChart = () => {
  useEffect(() => {
    const chartDom = document.getElementById('3');
    const myChart = echarts.init(chartDom);
    const option = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        bottom: '0',
        left: 'center'
      },
      series: [
        {
          name: 'Lead Type',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 15,
              fontWeight: 'bold',
              
            },

          },
          labelLine: {
            show: false
          },

          data: [
            { value: 345, name: 'Chrome' },
            { value: 935, name: 'Safari' },
            { value: 580, name: 'Others' }
          ],
          itemStyle: {
            color: function (params) {
              const colorList = ['#121318', '#AE1D23', '#DEE5ED'];
              return colorList[params.dataIndex];
            }
          },
        }
      ]

    };

    option && myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
  }, []);

  return <div id="3" style={{ width: '100%', height: '180px' }}></div>;
};

export default TopBrowserPieChart;
