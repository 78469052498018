import React from 'react'
import UsNavbar from '../../Components/USNavbar/UsNavbar';
import ListingNavbar from "../../Components/ListingNavbar/ListingNavbar";
import USHeading from '../../Components/USHeading/USHeading';
import MainGridUS from '../../Components/Carousel/MainGridUS/MainGridUS';

function PropertiesUS() {
  return (
    <div>
      <UsNavbar />
      <USHeading />
      <ListingNavbar />
      <MainGridUS />
    </div>
  )
}

export default PropertiesUS;
