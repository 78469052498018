import React, { useEffect } from 'react';
import * as echarts from 'echarts';

const MyChart = () => {
  useEffect(() => {
    const chartDom = document.getElementById('main');
    const myChart = echarts.init(chartDom);
    const option = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        bottom: '0',
        left: 'center'
      },
      graphic: [
        {
          type: 'text',
          left: 'center',
          top: 'center',
          style: {
            text: '235',
            textAlign: 'center',
            fill: '#333', // Text color
            fontSize: 20, // Text size
            fontWeight: 'bold', // Text weight
          }
        }
      ],
      series: [
        {
          name: 'Lead Type',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 15,
              fontWeight: 'bold',
              
            },

          },
          labelLine: {
            show: false
          },

          data: [
            { value: 1048, name: 'Buyers' },
            { value: 735, name: 'Sellers' },
            { value: 580, name: 'Rentals' }
          ],
          itemStyle: {
            color: function (params) {
              const colorList = ['#121318', '#AE1D23', '#DEE5ED'];
              return colorList[params.dataIndex];
            }
          },
        }
      ]

    };

    option && myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
  }, []);

  return <div className='PieChartDB' id="main" style={{ width: '100%', height: '300px' }}></div>;
};

export default MyChart;
