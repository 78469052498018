import React, {useState} from 'react'
import "./USProperties.css";
import USIMG from "../../../Assets/USIMG.jpg";
import BGIMGFlorida from "../../../Assets/BGIMGFlorida.jpg";
import { MdOutlineBed } from "react-icons/md";
import { LiaShowerSolid } from "react-icons/lia";
import { MdSquareFoot } from "react-icons/md";
import { GoPerson } from "react-icons/go";
import { PiLinkSimple } from "react-icons/pi";
import { FaCamera } from "react-icons/fa";
import { ImEnlarge2 } from "react-icons/im";
import { MdCompare } from "react-icons/md";
import { MdFavorite } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

function USProperties() {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [USIMG, BGIMGFlorida,];

  const nextImage = () => {
    setCurrentImage((currentImage + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImage((currentImage - 1 + images.length) % images.length);
  };

  return (
    <div className='ContainerUS'>
      <div className='imageUS'>
        <div className='imagezUS'>
          <IoIosArrowBack className = "lessIconUS" onClick={prevImage} />
          <img src={images[currentImage]} alt="Apartment" />
          <IoIosArrowForward className='greatIconUS' onClick={nextImage}/>
        </div>
        <div className="Tags">
          <div className="SaleTag">
            <p>For Selling the property  </p>
          </div>
          <div className="RentTag">
            <p>For Rent</p>
          </div>
        </div>
        <div className='imageItemsUS'>
          <div className='textUS'>
            <p>$1,000</p>
          </div>
          <div className='IconsUS'>
            <FaCamera title='cameraUS' />
            <ImEnlarge2 />
            <MdCompare />
            <MdFavorite />
          </div>
        </div>
      </div>
      <div className='locationUS'>
        <p style={{ color: "black" }}>123456 AVENUE Canada , Colombia, British, XYZ</p>
        <p style={{ margin: 0 }}>14555, 78 AVENUE</p>
      </div>
      <div className='featureConatinerUS'>
        <div className='featuresUS'>
          <div className='bedroomsUS'>
            <MdOutlineBed />
            <p>5</p>
          </div>
          <div className='showerUS'>
            <LiaShowerSolid />
            <p>5</p>
          </div>
          <div className='squarefootUS'>
            <MdSquareFoot />
            <p>3762 Sqft</p>
          </div>
        </div>
        <div className='btnContainerUS'>
          <button type="button">Details</button>
        </div>
      </div>
      <div className='propertyHeadingUS'>
        <p style={{ margin: 0 }}>RESIDENTIAL</p>
      </div>

      <div className='detailsUS'>
        <div className='agentUS'>
          <GoPerson />
          <p>Tom Miller</p>
        </div>
        <div className='postedUS'>
          <PiLinkSimple />
          <p>1 week ago</p>
        </div>
      </div>
    </div>
  )
}

export default USProperties;
