import React, { useState } from 'react'
import "./AgentListingsDetails.css";
import Image2 from "../../../Assets/Image2.jpg";
import Image3 from "../../../Assets/Image3.jpg";
import { MdOutlineBed } from "react-icons/md";
import { LiaShowerSolid } from "react-icons/lia";
import { MdSquareFoot } from "react-icons/md";
import { GoPerson } from "react-icons/go";
import { PiLinkSimple } from "react-icons/pi";
import { FaCamera } from "react-icons/fa";
import { ImEnlarge2 } from "react-icons/im";
import { MdCompare } from "react-icons/md";
import { MdFavorite } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

function AgentListingsDetails() {
    const [currentImage, setCurrentImage] = useState(0);
    const images = [Image2, Image3];

    const nextImage = () => {
        setCurrentImage((currentImage + 1) % images.length);
    };

    const prevImage = () => {
        setCurrentImage((currentImage - 1 + images.length) % images.length);
    };

    return (
        <section className='AgentListingDetailsMainDiv'>
            <div className='AgentListingDetailsInnerDiv'>
                <div className='AgentListingdetailsRow'>
                    <div className='AgentListingDetailsImageContainer'>
                        <div className='AgentListingDetailsImage'>
                            <IoIosArrowBack className="AgentListingDetailsArrowBack" onClick={prevImage} />
                            <img src={images[currentImage]} alt="Apartment" />
                            <IoIosArrowForward className='AgentListingDetailsArrowForward' onClick={nextImage} />
                        </div>
                        <div className='AgentListingDetailsImageItems'>
                            <div className='AgentListingDetailsIcons'>
                                <FaCamera />
                                <ImEnlarge2 />
                                <MdCompare />
                                <MdFavorite />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='AgentListingDetailsCol' >
                    <div className="AgentListingDetailsTags">
                        <div className="AgentListingDetailsSaleTag">
                            <p>For Selling the property  </p>
                        </div>
                        <div className="AgentListingDetailsRentTag">
                            <p>For Rent</p>
                        </div>
                    </div>
                    <div className='AgentListingDetailsLocation'>
                        <p className='AgentListingDetailsLocationValueText'>1064 CAVENDER CREEK RD, MINNEOLA, FL 34715</p>
                        <p className='AgentListingDetailsLocationText'>1064, Cavender Creek Road, Minneola, Lake County, Florida,</p>
                    </div>
                    <div className='AgentListingDetailsFeatureContainer'>
                        <div className='AgentListingDetailsFeatures'>
                            <div className='AgentListingDetailsBedrooms'>
                                <MdOutlineBed className='AgentListingDetailsOutlineBed' />
                                <p>5</p>
                            </div>
                            <div className='AgentListingDetailsShower'>
                                <LiaShowerSolid className='AgentListingDetailsShowerSolid' />
                                <p>5</p>
                            </div>
                            <div className='AgentListingDetailsSquarefoot'>
                                <MdSquareFoot className='AgentListingDetailsSquareFoot' />
                                <p>3762 Sqft</p>
                            </div>
                        </div>
                    </div>
                    <div className='AgentListingDetailsPropertyHeading'>
                        <p style={{ margin: 0 }}>RESIDENTIAL</p>
                    </div>

                    <div className='AgentListingDetailsSection'>
                        <div className='AgentListingDetailsAgent'>
                            <GoPerson className='AgentListingDetailsPersonIcon' />
                            <p style={{ color: "gray" }}>Tom Miller</p>
                        </div>
                        <div className='AgentListingDetailsPosted'>
                            <PiLinkSimple className='AgentListingDetailsLinkSimple' />
                            <p style={{ color: "gray" }}>1 week ago</p>
                        </div>
                    </div>
                </div>
                <div className='AgentListingDetailsPriceNBtn'>
                    <div className='AgentListingDetailsPriceText'>
                        <p style={{ margin: 0 }}>$1,000</p>
                    </div>
                    <div className='AgentListingDetailsBtnContainer'>
                        <button type="button">Details</button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AgentListingsDetails;
