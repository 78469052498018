import React from 'react';
import ImageSlider from '../RecentProperties/ImageSlider';
import "./MainImageSlider.css";


function MainImageSlider() {
    const numberOfSliders = [1, 2, 3, 4];
    return (
        <div className='maindiv'>
            <div className='textDiv'>
                <h6>Recent Properties</h6>
                <p>Looking for a home? Check out our newest listings!</p>
            </div>
            <div className='content'>
                <div className='slider'>
                    {numberOfSliders.map((index) => (
                        <ImageSlider key={index} />
                    ))}
                </div>
            </div>
            <div className='LoadMoreBtnConatiner'>
                <button>Load More</button>
            </div>
        </div>
    );
}

export default MainImageSlider;


// import React, { useState } from 'react';
// import ImageSlider from '../RecentProperties/ImageSlider';
// import "./MainImageSlider.css";
// import { FaGreaterThan, FaLessThan } from "react-icons/fa";

// function MainImageSlider() {
//     const numberOfSliders = [1, 2, 3, 4, 5, 6 , 7, 8, 9, 10];
//     const [startIndex, setStartIndex] = useState(0);

//     const handleNext = () => {
//         if (startIndex + 3 <= numberOfSliders.length - 4) {
//             setStartIndex(prev => prev + 1);
//         }
//     };

//     const handlePrevious = () => {
//         if (startIndex - 1 >= 0) {
//             setStartIndex(prev => prev - 1);
//         }
//     };
    

//     return (
//         <div className='maindiv'>
//             <div className='textDiv'>
//                 <h6>Recent Properties</h6>
//                 <p>Looking for a home? Check out our newest listings!</p>
//             </div>
//             <div className='content'>
//                 <div className="lessthanIcon" onClick={handlePrevious}>
//                     <FaLessThan style={{ color: "black" }} />
//                 </div>
//                 <div className='slider'>
//                     <div className='sliderInner' style={{ transform: `translateX(-${startIndex * 25}%)` }} >
//                     {numberOfSliders.slice(startIndex, startIndex + 4).map((index) => (
//                         <ImageSlider key={index} />
//                     ))}
//                     </div>
                    
//                 </div>
//                 <div className="greaterthanIcon" onClick={handleNext}>
//                     <FaGreaterThan style={{ color: "black" }} />
//                 </div>
//             </div>
//             <div className='ButtonConatiner'>
//                 <button>Load More</button>
//             </div>
//         </div>
//     );
// }

// export default MainImageSlider;
