import React from 'react'
import "./RecentDescriptionDetails.css";

function RecentDescriptionDetails() {
    return (
        <section className='MainDivDescriptionDetails'>
            <div className='RecentDescriptionDetailsInnerDiv'>
                <p className='DescriptionDetailsText'>Description</p>
                <p className='DescriptionTextBlue'>424 SE WILDFLOWER DRIVE</p>
                <p className='DescriptionDetailsTextP'>This 2023 contemporary showpiece boasts panoramic Cascade Mountain views and single-level luxury. The expansive great room has vaulted ceilings, a wall of windows framing Mt. Jefferson, Mt. Hood & city lights, a 12’ gas fireplace, and a dining area with sliders out to the covered deck and a xeriscaped fenced yard. Entertain & cook with style from the dream kitchen with gleaming finishes like the 9’ quartz island with seating, plus abundant storage including a walk-in pantry. The brilliant floor plan separates the primary suite from the wing with 3 bedrooms as well as the sun-flooded office. Walk to Juniper Hills Park for basketball, baseball, soccer, disc golf, playground, miles of paved walkways & a trail system, or congregate even closer at the neighborhood playground & picnic tables. The Aquatic Center, COCC, schools & St. Charles are also just minutes away. Madras has grown into a vibrant community with easy access to Bend & Redmond, and also 2 hours to Portland.</p>
            </div>
        </section>
    )
}

export default RecentDescriptionDetails;
