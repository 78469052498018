//Layout.js
import React from 'react'
import Home from '../Pages/Home/Home';
//import Header from '../Components/Header/Header';

function MainLayout() {
    return (
        <div>
            <Home/>
        </div>
    )
}

export default MainLayout;