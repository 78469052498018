import React, { useEffect } from 'react';
import * as echarts from 'echarts';

const DevicesPieChart = () => {
  useEffect(() => {
    const chartDom = document.getElementById('1');
    const myChart = echarts.init(chartDom);
    const option = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        bottom: '0',
        left: 'center'
      },
      series: [
        {
          name: 'Lead Type',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 15,
              fontWeight: 'bold',
              
            },

          },
          labelLine: {
            show: false
          },

          data: [
            { value: 1048, name: 'Mobiles' },
            { value: 735, name: 'Desktop' },
           
          ],
          itemStyle: {
            color: function (params) {
              const colorList = ['#121318', '#AE1D23', ];
              return colorList[params.dataIndex];
            }
          },
        }
      ]

    };

    option && myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
  }, []);

  return <div id="1" style={{ width: '100%', height: '180px' }}></div>;
};

export default DevicesPieChart;
