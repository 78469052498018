import React, {useState} from 'react'
import "./MyProfile.css";
import AutumnMakin from "../../Assets/AutumnMakin.jpg";
import TextEditor from '../TextEditor/TextEditor';
import SocialMedia from '../SocialMedia/SocialMedia';
import ChangePassword from '../ChangePassword/ChangePassword';

function MyProfile() {

  const [isClicked, setIsClicked] = useState(false);

  const handleInputClick = () => {
    setIsClicked(true);
};

  return (
    <div className='MyProfileMainDiv'>
      <div className='MyProfileInnerDiv'>
        <p>My Profile</p>
        <button>View Public Profile</button>
      </div>
      <p style = {{marginLeft: "20px"}}>Information</p>
      <section className='MainDataSection'>
        <div className='DashboardMyProfileImageContainer'>
          <div className='MyProfileAgentImageContainer'>
            <img src={AutumnMakin} alt="AgentName" />
          </div>
          <div className='MyProfleUpdatePictureContainer'>
            <button>Update Profile Picture</button>
          </div>
        </div>
        <div className='DashboardMyProfileFormContainer' >

          <div className='InformationInputfieldsRow1' style = {{marginTop: "-18px"}}>
            <div style={{ display: 'flex', flexDirection: "column" }}>
              <p>Username</p>
              <input type="text" placeholder='Username' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
            </div>
            <div>
              <p>Email</p>
              <input type="email" placeholder='Email' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
            </div>
          </div>

          <div className='InformationInputfieldsRow2'>
            <div style={{ display: 'flex', flexDirection: "column" }}>
              <p>First Name</p>
              <input type="text" placeholder='FirstName' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
            </div>
            <div>
              <p>Last Name</p>
              <input type="email" placeholder='LastName' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
            </div>
          </div>

          <div className='InformationInputfieldsRow3'>
            <p>Select Your Public Name</p>
            <input type="text" placeholder='PublicName' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
          </div>

          <div className='InformationInputfieldsRow4'>
            <div style={{ display: 'flex', flexDirection: "column" }}>
              <p>License </p>
              <input type="text" placeholder='License' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
            </div>
            <div>
              <p>Mobile</p>
              <input type="email" placeholder='Mobile' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
            </div>
          </div>

          <div className='InformationInputfieldsRow5'>
            <div style={{ display: 'flex', flexDirection: "column" }}>
              <p>Phone </p>
              <input type="text" placeholder='Phone' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
            </div>
            <div>
              <p>Language</p>
              <input type="email" placeholder='Language' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
            </div>
          </div>

          <div className='InformationInputfieldsRow6'>
            <div style={{ display: 'flex', flexDirection: "column" }}>
              <p>Company Name </p>
              <input type="text" placeholder='CompanyName' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
            </div>
            <div>
              <p>Address</p>
              <input type="email" placeholder='Address' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
            </div>
          </div>

          <div className='InformationInputfieldsRow7'>
            <p>Service Areas</p>
            <input type="text" placeholder='PublicName' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
          </div>

          <div className='InformationInputfieldsRow8'>
            <p>Specialities</p>
            <input type="text" placeholder='PublicName' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
          </div>

          <TextEditor/>
          <div className='UpdateProfileBtnContainer'>
            <button>Update Profile</button>
          </div>
        </div>
       
      </section>
      <SocialMedia/>
      <ChangePassword/>

    </div>
  )
}

export default MyProfile;
