import React, { useState } from 'react'
import "./CreateListingsPage5.css";
import { GrFormNextLink } from "react-icons/gr";
import { Link } from 'react-router-dom';

function CreateListingsPage5() {

    const [isClicked, setIsClicked] = useState(false);

    const handleInputClick = () => {
        setIsClicked(true);
    };

    return (
        <section className='CreateListingsPage5MainDiv'>
            <div className='CreateListingsPage5SectionInnerDiv' >
                <div className='CreateListingsPage5HeaderTextBtn'>
                    <p>Create Listings</p>
                    <button >Save as Draft</button>
                </div>
            </div>
            <div className='CreateListingsPage5MainFormSection'>
                <div className='Page5InputFieldsrow1'>
                    <div>
                        <p>Address</p>
                        <input type="text" placeholder='Address' onClick={handleInputClick}
                            className={isClicked ? 'input-clicked' : 'input-normal'} />
                    </div>
                    <div>
                        <p>Country *</p>
                        <input type="text" placeholder='Country' required onClick={handleInputClick}
                            className={isClicked ? 'input-clicked' : 'input-normal'} />
                    </div>
                </div>

                <div className='Page5InputFieldsrow2'>
                    <div>
                        <p>State</p>
                        <input type="text" placeholder='State' onClick={handleInputClick}
                            className={isClicked ? 'input-clicked' : 'input-normal'} />
                    </div>
                    <div>
                        <p>City * </p>
                        <input type="text" placeholder='City ' required onClick={handleInputClick}
                            className={isClicked ? 'input-clicked' : 'input-normal'} />
                    </div>
                </div>

                <div className='Page5InputFieldsrow3'>
                    <div>
                        <p>Area *</p>
                        <input type="text" placeholder='Area' required onClick={handleInputClick}
                            className={isClicked ? 'input-clicked' : 'input-normal'} />
                    </div>
                    <div>
                        <p>Zip/Postal Code</p>
                        <input type="text" placeholder='Zip/Postal Code' onClick={handleInputClick}
                            className={isClicked ? 'input-clicked' : 'input-normal'} />
                    </div>
                </div>

            </div>

            <div className='Page5MapContainer'>
                <div className='Page5MapContainerInnerDiv'>
                    <p>Drag and drop the pin on map to find exact location</p>
                    <div className='MapFrameCreateListingsPage5Container' >
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2924.2675937832673!2d-109.86666782337795!3d42.867195802910935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x875796a8faa826a9%3A0xccb88d30b89a612!2s34%20N%20Franklin%20Ave%20687%202430%2C%20Pinedale%2C%20WY%2082941%2C%20USA!5e0!3m2!1sen!2s!4v1714434981347!5m2!1sen!2s"
                            // width="700"
                            // height="350"
                            className='MapFrameCreateListingsPage5'
                            // style={{ border: "1px solid #DEE5ED", borderRadius: "15px", }}
                            allowFullScreen=""
                            loading="lazy"
                            title="Google Map"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                    <div className='PinAddressBtnContainer'>
                        <button>Place the pin in the address above</button>
                    </div>
                </div>
                <div className='Page5InputFieldsrow4'>
                    <div>
                        <p>Latitude</p>
                        <input type="text" placeholder='Enter the Latitude' required onClick={handleInputClick}
                            className={isClicked ? 'input-clicked' : 'input-normal'} />
                    </div>
                    <div>
                        <p>Longitude</p>
                        <input type="text" placeholder='Enter the Longitude' onClick={handleInputClick}
                            className={isClicked ? 'input-clicked' : 'input-normal'} />
                    </div>
                </div>
            </div>


            <div className='CreateListingsPage5Footer'>
                <div className='CreateListingsPage5BtnContainerFooter'>
                    <div className='CreateListingsPage5BackBtnIconContainer'>
                        <Link to="/CreateListings-Page4"> <button>Back</button>  </Link>
                    </div>
                    <div className='CreateListingsPage5NextBtnIconContainer'>
                        <Link to="/CreateListings-Page6" className='NextBtnLink'>   <button>Next
                            <GrFormNextLink style={{ fontSize: '30px', marginLeft: "10px" }} />
                        </button>
                        </Link>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default CreateListingsPage5;
