import React, {useState} from 'react'
import "./CreateListingsPage6.css";
import { Link } from 'react-router-dom';

function CreateListingsPage6() {

    const [isClicked, setIsClicked] = useState(false);

  const handleInputClick = () => {
    setIsClicked(true);
};


    return (
        <section className='CreateListingsPage6MainDiv'>
            <div className='CreateListingsPage6SectionInnerDiv' >
                <div className='CreateListingsPage6HeaderTextBtn'>
                    <p>Create Listings</p>
                    <button >Save as Draft</button>
                </div>
            </div>

            <div className='PrivateNoteSectionMainDiv'>
                <p>Write private note for this property, it will not display for public.</p>
                <textarea cols="30" rows="10" placeholder='Enter a note' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'}></textarea>
            </div>

            <div className='CreateListingsPage6Footer'>
                <div className='CreateListingsPage6BtnContainerFooter'>
                    <div className='CreateListingsPage6BackBtnIconContainer'>
                        <Link to="/CreateListings-Page5"> <button>Back</button>  </Link>
                    </div>
                    <div className='CreateListingsPage6SubmitBtnIconContainer'>
                     <Link to = "/Properties">  <button>Submit Property </button> </Link> 

                    </div>
                </div>
            </div>


        </section>
    )
}

export default CreateListingsPage6;
