import React from 'react'
import BGIMG from "../../../Assets/BGIMG.jpg"
import "./BackgroundImage.css";

function BackgroundImage() {
  return (
    <div className='background'>
      <img src={BGIMG} alt="BackgroundImage" />
    </div>
  )
}

export default BackgroundImage;

