import React from 'react';
import ImageSliderFlorida from "../PropertiesFlorida/ImageSliderFlorida"
import "./MainSliderFlorida.css";

function MainImageSliderFlorida() {
    const numOfSliders = [1, 2, 3, 4];

    return (
        <div className='Maindiv'>
            <div className='TextDiv' >
                <h6 style = {{fontSize: '25px'}}>New Homes For Sale In Florida</h6>
            </div>
            <div className='Content'>
                <div className='SliderMain'>
                    {numOfSliders.map((index) => (
                        <ImageSliderFlorida key={index} />
                    ))}
                </div>
            </div>
            <div className='LoadMoreButton_Conatiner'>
                <button>Load More</button>
            </div>
        </div>
    );
}

export default MainImageSliderFlorida;


