import React, {useState} from 'react'
import "./ChangePassword.css";

function ChangePassword() {
    const [isClicked, setIsClicked] = useState(false);

    const handleInputClick = () => {
      setIsClicked(true);
  };

  return (
    <section className='MyProfileChangePasswordMainDiv'>
            <div className='MyProfileChangePasswordInnerDiv'>
                <div className='ChangePasswordLeftSide'>
                    <p>Change Password</p>
                </div>
                <div className='ChangePasswordRightSide'>
                    <div className='ChangePasswordSectionRow1'>
                        <div>
                            <p>New Password</p>
                            <input type="text" placeholder='Enter your New Password' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
                        </div>
                        <div>
                            <p>Confirm New Password</p>
                            <input type="text" placeholder='Enter your New Password Again' onClick={handleInputClick}
            className={isClicked ? 'input-clicked' : 'input-normal'} />
                        </div>
                    </div>

                    <div className='UpdatePasswordBtnContainer'>
                        <button>Update Password</button>
                    </div>

                </div>
            </div>
        </section>
    )
  
}

export default ChangePassword;
