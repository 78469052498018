import React from 'react'
import "./AgentNumOfListings.css";

function AgentNumOfListings() {
  return (
    <section className='AgentNumOfListingsMainDiv'>
        <div className='AgentNumOfListingsInnerDiv'>
            <div className='AgentNumOfListingsPropertiesChart'>
                <div>
                    <p>Property Types</p>
                </div>
                <div>
                    <p>Property Status</p>
                </div>
                <div>
                    <p>Property Cities</p>
                </div>
            </div>
        </div>
    </section>
  )
}

export default AgentNumOfListings;
